import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordName, RouteRecordRaw } from "vue-router";
import DashboardPage from "../views/DashboardPage.vue";
import NewAlertPage from "../views/NewAlertPage.vue";
import SettingsPage from "../views/SettingsPage.vue";
import LogOutPage from "../views/LogOutPage.vue";
import ResetPasswordPage from "../views/ResetPasswordPage.vue";
import LogInPage from "../views/LoginPage.vue";
import JoinPage from "../views/JoinPage.vue";
import JoinPlanPage from "../views/JoinPlanPage.vue";
import LayoutDefault from "../views/shared/LayoutDefault.vue";
import LayoutAuth from "../views/shared/LayoutAuth.vue";

import { LoginFactory } from "@/utils/LoginManager";
import { IsAuthenticated } from "@/utils/helpers";
import { useUserStore } from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard/",
    name: "Dashboard",
    component: DashboardPage,
    meta: {
      layout: LayoutAuth,
    },
  },
  {
    path: "/alert/",
    name: "CreateAlert",
    component: NewAlertPage,
    meta: {
      layout: LayoutAuth,
    },
  },
  {
    path: "/settings",
    name: "Settings",
    component: SettingsPage,
    meta: {
      layout: LayoutAuth,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: LogInPage,
  },
  {
    path: "/logout",
    name: "Logout",
    component: LogOutPage,
    meta: {
      layout: LayoutDefault,
    },
  },
  {
    path: "/reset-password",
    name: "RessetPassword",
    component: ResetPasswordPage,
    meta: {
      layout: LayoutDefault,
    },
  },
  {
    path: "/join",
    name: "Join",
    component: JoinPage,
    meta: {
      layout: LayoutDefault,
    },
  },
  {
    path: "/join/plan",
    name: "Plan",
    component: JoinPlanPage,
    meta: {
      layout: LayoutDefault,
    },
  },
  {
    path: "/:id",
    component: () => import("../views/FolderPage.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Route guards
const publicPages: any = ["Login", "Logout", "RessetPassword", "Join"];
router.beforeEach((to, from) => {
  const isAuthenticated = IsAuthenticated();

  if (!isAuthenticated && DestinationIsPublic(to.name) == false) {
    return { name: "Login" };
  }
});

const DestinationIsPublic = (destination: any) => {
  for (let index = 0; index < publicPages.length; index++) {
    if (destination === publicPages[index]) {
      return true;
    }
  }
  return false;
};

export default router;
