import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createBlock(_component_swiper, {
    class: "hero",
    modules: _ctx.modules,
    pagination: { clickable: true },
    autoplay: true
  }, {
    default: _withCtx(() => [
      _createVNode(_component_swiper_slide, null, {
        default: _withCtx(() => [
          _createTextVNode("Magic: The Gathering")
        ]),
        _: 1
      }),
      _createVNode(_component_swiper_slide, null, {
        default: _withCtx(() => [
          _createTextVNode("Yu-Gi-Oh!")
        ]),
        _: 1
      }),
      _createVNode(_component_swiper_slide, null, {
        default: _withCtx(() => [
          _createTextVNode("Pokémon")
        ]),
        _: 1
      }),
      _createVNode(_component_swiper_slide, null, {
        default: _withCtx(() => [
          _createTextVNode("and so much more.")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modules"]))
}