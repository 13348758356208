<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Create Price Alert</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Create Price Alert</ion-title>
        </ion-toolbar>
      </ion-header>
      <PageNote
        text="Create a new alert to be notified
      when a product reaches a price desired range."
      />
      <ion-item lines="none" style="margin-bottom: 32px">
        <ImportStepCounter :step="step" />
      </ion-item>
      <ImportProduct
        @ProductImportedEvent="HandleProductImportedEvent($event)"
        @BusyStateChangedEvent="HandleBusyStateChangeEvent($event)"
        @Exception="ResetForm()"
        class="ion-margin-bottom"
      />
      <ion-grid>
        <ion-row class="ion-margin-top">
          <ion-col size-md="6" size="12">
            <ion-row v-if="!hideProduct">
              <ion-col
                size-md="4"
                size="12"
                class="product-image-container"
                v-if="product.url"
              >
                <a :href="product.url" target="_blank">
                  <img
                    class="product-image"
                    :src="product.imageUrl"
                    :alt="product.cleanName"
                /></a>
              </ion-col>
              <ion-col
                class="ion-justify-content-center ion-align-items-center"
                style="display: flex; min-height: 100px"
                size-md="4"
                size="12"
                v-else
              >
                <ion-spinner class="ion-rt" color="primary"></ion-spinner>
              </ion-col>
              <ion-col size-md="8" size="12" class="product-data">
                <h2
                  style="font-size: 1.7rem"
                  class="ion-margin-top-0 ion-margin-bottom-0 font-weight-bold"
                >
                  {{ product.cleanName }}
                </h2>
                <h4 class="ion-margin-top-0">
                  {{ product.groupName }}
                </h4>
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-col v-if="step !== StepCount.One" size-md="6" size="12">
            <ion-row>
              <ion-col>
                <ImportSkuSelector
                  @SkuSelectionEdited="HandleSkuSelectionEditedEvent($event)"
                  :skus="product.skus"
                  :hint="conditionsHintText"
                />
              </ion-col>
            </ion-row>
          </ion-col>
        </ion-row>
        <ion-row class="ion-margin-top">
          <ion-col>
            <ion-card v-if="step !== StepCount.One">
              <ion-card-header style="background-color: var(--ion-color-light)">
                <ion-card-title>
                  <ion-text
                    style="font-size: 30px; position: relative; top: 7px"
                    class="tcgplayer"
                  >
                    <ion-icon :icon="analytics"></ion-icon>
                  </ion-text>
                  Configure TCGPlayer Alert
                </ion-card-title>
              </ion-card-header>
              <ion-row
                v-if="step !== StepCount.Three"
                class="ion-margin-bottom"
              >
                <h4 class="ion-margin-start">
                  <ion-text color="warning">
                    <ion-icon :icon="warning"></ion-icon>
                  </ion-text>
                  You must select a condition to configure an alert
                </h4>
              </ion-row>
              <div :class="step == StepCount.Two ? 'invisible' : 'visible'">
                <ion-row>
                  <ion-col>
                    <ion-item lines="none">
                      <ion-text color="medium" slot="start">Condition</ion-text>
                      <ion-text color="medium" slot="end"
                        >Lowest Listing</ion-text
                      >
                    </ion-item>
                    <ion-item v-for="sku in pricingTableSkus" :key="sku.skuId">
                      <ion-label>{{ sku.conditionName }}</ion-label>
                      <!--Shipping Included-->
                      <div v-if="IsShippingIncluded">
                        <ion-text
                          v-if="sku.price.lowestListingPrice < 0"
                          slot="end"
                        >
                          <ion-spinner></ion-spinner>
                        </ion-text>
                        <ion-text
                          color="medium"
                          v-if="sku.price.lowestListingPrice == 0"
                          slot="end"
                          >out of stock</ion-text
                        >
                        <ion-text
                          class="price"
                          v-if="sku.price.lowestListingPrice > 0"
                          slot="end"
                          >{{ toUSD(sku.price.lowestListingPrice) }}</ion-text
                        >
                      </div>
                      <!--Shipping excluded-->
                      <div v-else>
                        <ion-text v-if="sku.price.lowPrice < 0" slot="end">
                          <ion-spinner></ion-spinner>
                        </ion-text>
                        <ion-text
                          color="medium"
                          v-if="sku.price.lowPrice == 0"
                          slot="end"
                          >out of stock</ion-text
                        >
                        <ion-text
                          class="price"
                          v-if="sku.price.lowPrice > 0"
                          slot="end"
                          >{{ toUSD(sku.price.lowPrice) }}</ion-text
                        >
                      </div>
                    </ion-item>
                    <ion-item lines="none">
                      <ion-icon
                        class="shipping-excluded ion-margin-left-0"
                        :class="{
                          'shipping-excluded-nested': !IsShippingIncluded,
                        }"
                        slot="end"
                        style="fontsize: 20px"
                        :icon="banOutline"
                      >
                      </ion-icon>
                      <ion-icon
                        slot="end"
                        style="fontsize: 20px"
                        class="ion-margin-left-0"
                        :src="`${publicPath}fonts/truck-solid.svg`"
                      >
                      </ion-icon>
                      <ion-text
                        class="ion-margin-left-0"
                        color="medium"
                        slot="end"
                        v-if="IsShippingIncluded"
                      >
                        &nbsp;shipping included</ion-text
                      >
                      <ion-text
                        class="ion-margin-left-0"
                        color="medium"
                        slot="end"
                        v-else
                      >
                        &nbsp;shipping excluded</ion-text
                      >
                    </ion-item>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="12" size-md="6">
                    <ion-item lines="none">
                      <ion-label position="fixed" for="desired-price-input"
                        >Set Price</ion-label
                      >
                    </ion-item>
                    <ion-item
                      :class="{
                        'ion-invalid': !isDesiredPriceInputValid,
                        'invalid-item': !isDesiredPriceInputValid,
                      }"
                    >
                      <ion-icon
                        style="fontsize: 20px"
                        slot="start"
                        :src="`${publicPath}fonts/dollar-sign-solid.svg`"
                      ></ion-icon>
                      <ion-input
                        id="desired-price-input"
                        placeholder="5.00"
                        inputmode="decimal"
                        type="number"
                        value="number"
                        v-model="desiredPriceInput"
                      ></ion-input>
                    </ion-item>
                    <ion-item lines="none">
                      <ion-text slot="end">include shipping</ion-text>
                      <ion-toggle
                        class="ion-margin-start"
                        v-model="IsShippingIncluded"
                        slot="end"
                      ></ion-toggle>
                    </ion-item>
                  </ion-col>
                  <ion-col size="12" size-md="6">
                    <AlertTypeInput
                      :InitialAlertType="AlertMode.PriceDrop"
                      @AlertTypeChangedEvent="
                        HandleAlertTypeChangedEvent($event)
                      "
                    />
                  </ion-col>
                </ion-row>
                <ion-row class="ion-margin-top ion-margin-bottom">
                  <ion-col>
                    <ion-button
                      v-if="!desiredPriceComputed"
                      disabled
                      size="large"
                      color="success"
                      expand="block"
                      >Save</ion-button
                    >
                    <ion-button
                      v-else
                      @click="SaveAlert()"
                      :disabled="IsSaveBusy"
                      size="large"
                      color="success"
                      expand="block"
                      style="white-space: normal"
                    >
                      <span v-if="IsSaveBusy">
                        Saving...&nbsp;&nbsp;
                        <ion-spinner class=""></ion-spinner>
                      </span>
                      <span v-else>
                        Save alert for {{ desiredPriceComputed }}
                      </span>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </div>
            </ion-card>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<style scoped>
@media (max-width: 767px) {
  .product-image-container {
    max-width: unset;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .product-image-container {
    max-width: fit-content !important;
  }
}
.product-data {
  margin-top: 0;
  /*margin-left: -245px;*/
}
.price {
  font-weight: bold;
}
.tcgplayer {
  color: #0835db;
}

#desired-price-input:active {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}
.shipping-excluded {
  visibility: hidden;
  opacity: 0;
  position: relative;
  margin-top: 0px;
  top: 6px;
  left: 6px;
  rotate: -90deg;
  transition: left 230ms ease-in-out, visibility 230ms ease-in-out,
    opacity 230ms ease-in-out, rotate 230ms ease-in-out;
  font-size: 225%;
  color: rgba(217, 83, 79, 1);
}
.shipping-excluded-nested {
  visibility: visible;
  opacity: 1;
  left: 28px;
  rotate: initial;
}
</style>

<script lang="ts">
import { defineComponent, computed, reactive, ref } from "vue";
import {
  IonButtons,
  IonButton,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonGrid,
  IonCol,
  IonRow,
  IonIcon,
  IonInput,
  IonLabel,
  IonToggle,
  IonSpinner,
  IonCardTitle,
  IonCardHeader,
  IonCard,
  IonText,
} from "@ionic/vue";
import { analytics, warning, banOutline, checkmarkCircleOutline } from "ionicons/icons";
import PageNote from "../components/PageNote.vue";
import { SniperAPI } from "../utils/AxiosInstance.js";
import { successToast, toast } from "../utils/SimpleToast.js";
import { toUSD } from "../utils/helpers";
import ImportProduct from "@/components/ImportProduct.vue";
import AlertTypeInput from "@/components/AlertTypeInput.vue";
import {
  ImportedProduct,
  NewProductAlertViewModel,
  SelectableTCGPlayerSKU,
} from "@/models/Product";
import ImportStepCounter from "@/components/ImportStepCounter.vue";
import ImportSkuSelector from "@/components/ImportSkuSelector.vue";
import { StepCount } from "@/models/StepCountManager";
import { AlertMode } from "@/utils/Enums";

export default defineComponent({
  components: {
    IonButtons,
    IonButton,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    PageNote,
    ImportProduct,
    ImportStepCounter,
    IonItem,
    IonGrid,
    IonCol,
    IonRow,
    ImportSkuSelector,
    IonIcon,
    IonInput,
    IonLabel,
    IonToggle,
    IonSpinner,
    IonCardTitle,
    IonCardHeader,
    IonCard,
    IonText,
    AlertTypeInput,
  },
  setup() {
    // Data
    const product = ref<ImportedProduct>(new ImportedProduct());
    const alert = ref<NewProductAlertViewModel>(new NewProductAlertViewModel());
    const pricingTableSkus = reactive<SelectableTCGPlayerSKU[]>([]);
    const IsShippingIncluded = ref(true);
    const isBusy = ref(false);
    const IsSaveBusy = ref(false);
    const step = ref<StepCount>(StepCount.One);
    const hideProduct = ref<boolean>(true);
    const desiredPriceInput = ref<string>("");
    const isSaveDisabled = ref<boolean>(true);
    const isDesiredPriceInputValid = ref<boolean>(false); //Controls red border around desired price input
    const publicPath = process.env.BASE_URL;

    // Event Handlers
    const HandleProductImportedEvent = (args: ImportedProduct) => {
      ClearForm();
      PopulateFormWithNewProduct(args);
    };

    const HandleSkuSelectionEditedEvent = (args: any[]) => {
      let checkedSkus = args.filter(function (sku: any) {
        return sku.isChecked;
      });

      // Typescript will not automatically cast types like C#.
      // Need to instantiate a new object for each selected SKU
      let typedcheckedSkus: SelectableTCGPlayerSKU[] = [];
      checkedSkus.forEach((e: any) => {
        let sku = new SelectableTCGPlayerSKU();
        sku.skuId = e.skuId;
        sku.productId = e.productId;
        sku.languageId = e.languageId;
        sku.languageName = e.languageName;
        sku.languageAbbreviation = e.languageAbbreviation;
        sku.printingId = e.printingId;
        sku.conditionId = e.printingId;
        sku.conditionName = e.conditionName;
        //sku.dropDownDisplayName = e.dropDownDisplayName;
        sku.conditionAbbreviation = e.conditionAbbreviation;
        sku.isChecked = e.isChecked;
        typedcheckedSkus.push(sku);
      });

      RefreshPricingTable(typedcheckedSkus);
    };

    const HandleBusyStateChangeEvent = (_isBusy: boolean) => {
      isBusy.value = _isBusy;

      if (_isBusy === true) {
        hideProduct.value = false;
        ClearForm();
        step.value = StepCount.One;
      }
    };

    const HandleAlertTypeChangedEvent = (mode: AlertMode) => {
      alert.value.PriceRestriction = mode;
    };

    //Business Logic
    const PopulateFormWithNewProduct = (p: ImportedProduct) => {
      product.value = p;
      step.value = StepCount.Two;
    };

    const RefreshPricingTable = (checkedSkus: SelectableTCGPlayerSKU[]) => {
      let skuIds: string[] = [];
      pricingTableSkus.length = 0; // Make new array in the only way Vue 3 will be happy about it.

      checkedSkus.forEach((e: SelectableTCGPlayerSKU) => {
        pricingTableSkus.push(e);
        skuIds.push(e.skuId);
      });

      let UpdatePricingArray = (e: any) => {
        for (let i = 0; i < pricingTableSkus.length; i++) {
          if (pricingTableSkus[i].skuId == e.skuId) {
            pricingTableSkus[i].price.skuId = e.skuId;
            pricingTableSkus[i].price.lowPrice = !e.lowPrice ? 0 : e.lowPrice;
            pricingTableSkus[i].price.lowestListingPrice = !e.lowestListingPrice
              ? 0
              : e.lowestListingPrice;
          }
        }
      };

      if (checkedSkus.length === 0) {
        step.value = StepCount.Two;
        return;
      }
      step.value = StepCount.Three;

      SniperAPI.post("alert/GetTCGPlayerPrices", skuIds).then(function (
        response: any
      ) {
        if (!response.data.success) {
          step.value = StepCount.Two;
          return;
        }
        response.data.results.forEach((e: any) => {
          UpdatePricingArray(e);
        });
      });
    };

    // Ready the form so that a new product can be populated
    const ClearForm = () => {
      step.value = StepCount.One;
      product.value = new ImportedProduct();
    };

    // Reset form to initial state
    const ResetForm = () => {
      ClearForm();
      hideProduct.value = true;
    }
    const IsValidDesiredPrice = () => {
      let input = desiredPriceInput.value;
      let floatInput = parseFloat(desiredPriceInput.value);

      if (
        input == "" &&
        alert.value.PriceRestriction !== AlertMode.AnyChangeInPrice
      ) {
        isDesiredPriceInputValid.value = true;
        isSaveDisabled.value = true;
        alert.value.DesiredPrice = "";
        return false;
      }

      if (
        Number.isNaN(floatInput) &&
        alert.value.PriceRestriction !== AlertMode.AnyChangeInPrice
      ) {
        isDesiredPriceInputValid.value = false;
        isSaveDisabled.value = true;
        alert.value.DesiredPrice = "";
        return false;
      }
      // //Percent Validation
      //           if (floatInput < 0 && this.isPercentToggled === false) { //Desired percents are usually negative
      //               this.inputInvalid = true;
      //               this.btnDisabled = true;
      //               this.cardAlert.desiredPrice = 0;
      //               return false;
      //           }
      //           if (this.isPercentToggled && floatInput < -1) { //Suppress negative percents > -100
      //               this.inputInvalid = true;
      //               this.btnDisabled = true;
      //               this.cardAlert.desiredPrice = 0;
      //               return false;
      //           }
      //           if (this.isPercentToggled && floatInput > 1) { //Allow percent inputs up to 100%
      //               this.inputInvalid = true;
      //               this.btnDisabled = true;
      //               this.cardAlert.desiredPrice = 0;
      //               return false;
      //           }
      if (floatInput > 1000000000) {
        //Suppress abnormally large inputs
        isDesiredPriceInputValid.value = false;
        isSaveDisabled.value = true;
        alert.value.DesiredPrice = "";
        return false;
      }

      if (floatInput < 0) {
        console.error("Desired price is less than or equal to zero.");
        isDesiredPriceInputValid.value = false;
        isSaveDisabled.value = true;
        alert.value.DesiredPrice = "";
        return false;
      }
      isDesiredPriceInputValid.value = true;
      isSaveDisabled.value = false;
      return true;
    };

    const IsValidAlert = (): boolean => {
      if (alert.value.Skus.length > 0) {
        console.error("No skus selected.");
        return false;
      }

      return true;
    };

    const SaveAlert = () => {
      IsSaveBusy.value = true;

      // Build payload
      alert.value.Skus.length = 0;
      pricingTableSkus.forEach((e: SelectableTCGPlayerSKU) => {
        // let sku = new TCGPlayerSKU();
        // sku.skuId = e.skuId;
        // sku.productId = e.productId;
        // sku.languageId = e.languageId;
        // sku.languageName = e.languageName;
        // sku.languageAbbreviation = e.languageAbbreviation;
        // sku.printingId = e.printingId;
        // sku.conditionId = e.conditionId;
        // sku.conditionName = e.conditionName;
        // sku.dropDownDisplayName = e.dropDownDisplayName;
        // sku.conditionAbbreviation = e.conditionAbbreviation;
        e.price.skuId = e.skuId;
        alert.value.Skus.push(e);
      });
      alert.value.DesiredPrice = parseFloat(desiredPriceInput.value).toFixed(2);
      alert.value.GroupName = product.value.groupName;
      alert.value.ProductName = product.value.name;
      alert.value.GroupId = product.value.groupId.toString();
      alert.value.ProductId = product.value.productId;
      alert.value.PriceRestriction; // TODO
      alert.value.CardLink = product.value.url;
      alert.value.PictureLink = product.value.imageUrl;
      alert.value.IsShippingIncluded = IsShippingIncluded.value;

      if (!IsValidAlert) {
        toast("An error occured. Please try again later.");
        return;
      }

      SniperAPI.post(`alert/CreateAlert`, alert.value)
        .then(function (response) {
          if (response.data.success) {
            let toastMessage: string;

            // Successfully saved alert
            if (response.data.results > 1)
              toastMessage = `Success! You have created ${response.data.results.toString()} alerts.`;
            else toastMessage = `Suceess! You have created an alert.`;
            successToast(toastMessage);
            pricingTableSkus.length = 0;
            ResetForm();
          } else {
            toast(response.data.error.toString());
            console.error(response.data.error.toString());
          }
        })
        .catch(function (error) {
          toast("An unknown error occured. Please try again later.");
          console.error(error.toString());
        })
        .then(function () {
          IsSaveBusy.value = false;
        });
    };

    //Computed props
    const conditionsHintText = computed(() => {
      if (pricingTableSkus.length == 0) {
        return "Select SKUs to view pricing";
      }
      if (pricingTableSkus.length == 1) {
        return "1 condition selected";
      }
      return pricingTableSkus.length.toString() + " conditions selected";
    });

    const desiredPriceComputed = computed(() => {
      let input = desiredPriceInput.value;
      let floatInput = parseFloat(input);

      //Prechecks before validation
      if (!IsValidDesiredPrice()) {
        return "";
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      alert.value.DesiredPrice = floatInput.toFixed(2);

      if (pricingTableSkus.length === 0) {
        return "";
      }

      let priceDisplayString = toUSD(alert.value.DesiredPrice);

      if (alert.value.PriceRestriction === AlertMode.PriceDrop) {
        return (priceDisplayString += " or lower");
      } else if (alert.value.PriceRestriction === AlertMode.AnyChangeInPrice) {
        return "all changes in price.";
      } else {
        return priceDisplayString + " or higher";
      }
    });

    return {
      HandleProductImportedEvent,
      product,
      analytics,
      IsShippingIncluded,
      HandleSkuSelectionEditedEvent,
      HandleBusyStateChangeEvent,
      HandleAlertTypeChangedEvent,
      pricingTableSkus,
      toUSD,
      conditionsHintText,
      StepCount,
      step,
      hideProduct,
      warning,
      desiredPriceInput,
      AlertMode,
      desiredPriceComputed,
      IsSaveBusy,
      SaveAlert,
      isDesiredPriceInputValid,
      toast,
      banOutline,
      publicPath,
      ResetForm,
    };
  },
});
</script>
