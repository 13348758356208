import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, {
    class: "isoloated-item",
    href: "javascript:void(0);",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.LaunchModal())),
    detail: "",
    lines: _ctx.linesValue
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_label, null, {
        default: _withCtx(() => [
          _createTextVNode("Conditions")
        ]),
        _: 1
      }),
      _createVNode(_component_ion_text, { color: "medium" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.hint), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["lines"]))
}