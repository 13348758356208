<template>
  <ion-card class="upgrade-card">
    <ion-row>
      <ion-col size-sm="12" size-md="8" class="pricing-desc">
        <h5 style="font-weight: bold">BASE</h5>
        <p>Casual gamers and hobbyists.</p>
        <div class="pricing-features">
          <ul class="side-by-side">
            <li>
              <ion-text>
                <ion-icon color="primary" :icon="checkmarkSharp"></ion-icon>
                <strong> Up to 15 </strong> Active Alerts (SKUs)
              </ion-text>
            </li>
            <li>
              <ion-text>
                <ion-icon color="primary" :icon="mail"></ion-icon>
                Email Notifications
              </ion-text>
            </li>
            <li>
              <ion-text>
                <ion-icon color="primary" :icon="reload"></ion-icon>
                <strong> Frequent </strong> price checks
              </ion-text>
            </li>
          </ul>
        </div>
      </ion-col>
      <ion-col size-sm="12" size-md="4" class="pricing-action-area">
        <div class="pricing-meta"></div>
        <div class="pricing-price"><span class="price-unit">$</span>0</div>
        <span class="price-tenure">Monthly</span>
        <ion-button v-if="upgradeBusy" color="tertiary" class="ion-margin-top" size="default" disabled>
          <ion-spinner style="margin-right: 6px" name="circular"></ion-spinner>
          Select Plan
        </ion-button>
        <ion-button v-else @click="onClick()" color="tertiary" class="ion-margin-top" size="default">
          Select Plan</ion-button>
      </ion-col>
    </ion-row>
  </ion-card>
</template>
<style scoped>
ion-icon {
  font-size: 17px;
}

@media only screen and (max-width: 1400px) {
  ion-button {
    font-size: medium;
  }
}

ion-button {
  max-width: 100%;
}

.pricing-features ion-icon {
  position: relative;
  top: 3px;
}

.price-tenure {
  display: block;
  text-transform: uppercase;
}

.pricing-meta {
  color: #999;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0px;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.pricing-price span {
  display: inline-block;
  vertical-align: top;
  margin: 7px 3px 0 0;
  font-weight: 600;
}

.price-unit {
  font-size: 22px;
  margin-right: 0px;
}

.pricing-price {
  font-size: 64px;
  color: #333;
}

.pricing-box.pricing-extended .pricing-action-area .pricing-price span.price-tenure {
  display: block;
  margin: 10px 0 0 0;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 14px;
}

.pricing-box.pricing-extended .pricing-action-area .pricing-price {
  padding-bottom: 20px;
}

.side-by-side {
  float: left;
  padding-left: 0px;
}

@media only screen and (max-width: 575px) {
  .side-by-side li {
    float: none;
  }
}

@media only screen and (min-width: 576px) {
  .side-by-side li {
    float: left;
    width: 50%;
  }
}

.side-by-side li {
  list-style: none;
  padding-left: 0px;
  padding-top: 8px;
  padding-bottom: 8px;

  font-size: 15px;
  font-weight: 600;
}

.pricing-desc {
  padding: 40px;
}

.upgrade-card {
  min-width: 80%;
}

.pricing-action-area {
  background-color: #f9f9f9;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  padding: 30px;
  text-align: center;
}
</style>
<script lang="ts">
import {
  IonCard,
  IonRow,
  IonCol,
  IonIcon,
  IonText,
  IonButton,
  IonSpinner,
} from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { toast } from "../utils/SimpleToast.js";
import { GetStripeInstance } from "../utils/helpers";
import {
  logoDiscord,
  reload,
  phonePortraitOutline,
  mail,
  checkmarkSharp,
  lockOpen,
  infinite,
} from "ionicons/icons";
import { SniperAPI } from "@/utils/AxiosInstance.js";
import router from "../router/index";
import { useUserStore } from "@/store";
import { PlanName } from "@/utils/Enums";

export default defineComponent({
  components: {
    IonCard,
    IonRow,
    IonCol,
    IonIcon,
    IonText,
    IonButton,
    IonSpinner,
  },
  setup() {
    const upgradeBusy = ref<boolean>(false);
    const planName = PlanName.BASE;
    const userStore = useUserStore();

    const onClick = () => {
      upgradeBusy.value = true;

      SniperAPI.post("/join/subscription", null, {
        params: {
          planName,
        },
      })
        .then((response) => {
          let r = response.data;
          if (r.success) {
            userStore.setUserIsLoggedIn(true);
            router.push("/dashboard");
          } else {
            toast("An unknown error occured. Please try again later.");
          }
        })
        .catch(() => {
          toast("An unknown error occured. Please try again later.");
        })
        .finally(() => {
          upgradeBusy.value = false;
        });
    };
    return {
      logoDiscord,
      reload,
      phonePortraitOutline,
      mail,
      checkmarkSharp,
      lockOpen,
      infinite,
      onClick,
      upgradeBusy,
    };
  },
  // mounted() {
  // },
  //props: {},
});
</script>
