import { resolveComponent as _resolveComponent, createVNode as _createVNode, withKeys as _withKeys, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b37e909c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "container" }
const _hoisted_2 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_img, {
              class: "landing-page-logo",
              src: "https://cdn.tcgsniper.com/TCGSniperLogo.svg"
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ion_grid, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_col, {
                        offset: "0",
                        "offset-md": "3",
                        size: "12",
                        "size-md": "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_item, { class: "ion-margin-bottom" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_input, {
                                label: "Email",
                                "label-placement": "floating",
                                onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.Login()), ["enter"])),
                                modelValue: _ctx.email,
                                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
                                type: "email"
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_item, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_input, {
                                label: "Password",
                                "label-placement": "floating",
                                onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.Login()), ["enter"])),
                                modelValue: _ctx.pass,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pass) = $event)),
                                type: "password"
                              }, null, 8, ["modelValue"])
                            ]),
                            _: 1
                          }),
                          (_ctx.isBusy)
                            ? (_openBlock(), _createBlock(_component_ion_button, {
                                key: 0,
                                size: "large",
                                class: "loginbtn",
                                expand: "block",
                                disabled: ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_spinner, { name: "circular" }),
                                  _createTextVNode("     Login")
                                ]),
                                _: 1
                              }))
                            : (_openBlock(), _createBlock(_component_ion_button, {
                                key: 1,
                                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.Login())),
                                size: "large",
                                class: "loginbtn",
                                expand: "block"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Login")
                                ]),
                                _: 1
                              })),
                          _createVNode(_component_ion_button, {
                            class: "loginbtn",
                            fill: "clear",
                            "router-link": "/reset-password"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Forgot password")
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_button, {
                            "router-link": "/join",
                            class: "loginbtn",
                            fill: "clear"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode("Sign Up")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}