<template>
    <ion-item-sliding ref="slidingItem">
        <ion-item :detail="true" button @click="OpenModal(EditProductModal)">
            <div class="flex-container" v-bind:class="{ 'disabled-product': !product.IsActive }">
                <ion-img class="product-image" :alt="product.ProductName" :src="product.PictureLink"
                    @click.stop="window.open(product.CardLink, '_blank')"></ion-img>
                <div class="text-container">
                    <div class="row product-row ion-margin-top">
                        <div class="left">
                            <a @click.stop :href="product.CardLink" target="_blank" class="hoverable product-hyperlink">
                                <ion-text>
                                    <strong>
                                        {{ product.ProductName }}
                                    </strong>
                                    - {{ product.GroupName }}
                                    <ion-icon class="appear-on-hover" :icon="openOutline"></ion-icon>
                                </ion-text>
                            </a>
                        </div>
                        <div class="right">
                            <strong> {{ toUSD(product.DesiredPrice) }}</strong>
                        </div>
                    </div>
                    <div class="row details-row">
                        <div class="left">
                            <ion-text color="medium">
                                {{ product.ConditionName }}
                            </ion-text>
                        </div>
                        <div class="right">
                            <ion-text v-if="product.CurrentPrice" color="medium">
                                {{ toUSD(product.CurrentPrice) }}
                            </ion-text>
                            <ion-text v-else color="medium">
                                out of stock
                            </ion-text>
                        </div>
                    </div>
                    <div class="row ion-margin-top chip-row">
                        <div class="left chip-container">
                            <SnoozedChip v-if="product.IsSnoozed()" :product="product"
                                @SnoozeDismissedEvent="HandleSnoozeDismissedEvent()">
                            </SnoozedChip>
                            <ion-chip class="price-mode-chip" style="  background: none !important;" :outline="true"
                                color="medium">
                                <ion-icon :icon="GetPriceRestrictionIcon(product.PriceRestriction)"
                                    :color="GetPriceRestrictionColor(product.PriceRestriction)"></ion-icon>
                                <ion-label class="price-mode-label">{{
                                    GetPriceRestrictionText(product.PriceRestriction)
                                }}</ion-label>
                            </ion-chip>
                            <ShippingChip :IsShippingIncluded="product.IsShippingIncluded"></ShippingChip>
                        </div>
                    </div>
                </div>
            </div>
        </ion-item>
        <ion-item-options @ionSwipe="Delete()" side="end">
            <ion-item-option v-if="product.IsActive === false" color="success" @click="Activate()">
                <ion-icon :icon="power"></ion-icon>
                &nbsp;Activate
            </ion-item-option>
            <ion-item-option color="danger" expandable @click="Delete()">
                <ion-icon :icon="trashOutline"></ion-icon>
                &nbsp;Delete
            </ion-item-option>
        </ion-item-options>
    </ion-item-sliding>
</template>
<style scoped>
@media only screen and (max-width: 575px) {

    .product-image {
        display: none !important;
    }
}

ion-item::part(native) {
    padding-left: 0px;
}

.hoverable {
    color: inherit;
    text-decoration: inherit;
}

.hoverable:hover {
    color: var(--ion-color-primary);
    text-decoration: underline;
}

.appear-on-hover {
    display: none;
}

.hoverable:hover .appear-on-hover {
    display: inline-block;
}

.chip-row,
.chip-container {
    overflow-x: hidden;
    display: flex;
    width: 100%;
}

.price-mode-chip {
    display: flex;
    align-items: center;
    cursor: initial;
    margin-left: 0px;
}

.price-mode-chip>ion-icon,
.price-mode-chip> {
    display: inline-block;
    flex: none;
}

.price-mode-label {
    display: inline-block;
    white-space: nowrap;
}

.flex-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.product-image {
    max-width: 100px;
    min-width: 90px;
    height: auto;
    object-fit: contain;
}

.text-container {
    margin-left: 10px;
    flex-grow: 1;
}

.row {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.details-row {
    padding-top: 5px;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
    padding-left: 15px;
}

.disabled-product {
    opacity: 0.5;
}
</style>
<script lang="ts">
import {
    IonItem, IonImg, IonText,
    IonIcon, IonLabel, IonChip, modalController,
    IonItemOptions, IonItemOption, IonItemSliding
} from "@ionic/vue";
import { PropType, defineComponent, ref } from "vue";
import { successToast, toast } from "../utils/SimpleToast.js";
import { toUSD } from "../utils/helpers";
import { AlertMode } from "@/utils/Enums";
import {
    power, share, trash, openOutline,
    trendingDown, trendingUp, statsChart, createOutline, trashOutline, moon,
    closeCircle
} from "ionicons/icons";
import { DashboardProduct } from "@/models/Product";
import EditProductModal from "@/views/modals/EditProductModal.vue";
import ShippingChip from "./ShippingChip.vue";
import SnoozedChip from "./SnoozedChip.vue";
import { SniperAPI } from "../utils/AxiosInstance";
export default defineComponent({
    components: {
        IonItem,
        SnoozedChip,
        ShippingChip,
        IonImg,
        IonText,
        IonItemOption,
        IonItemOptions,
        IonChip,
        IonItemSliding,
        IonIcon,
        IonLabel,

    },
    emits: ["ProductEditedEvent", "ProductDeletedEvent"],
    setup(props, { emit }) {
        const slidingItem = ref();

        // Chips
        const GetPriceRestrictionText = (mode: AlertMode) => {
            switch (mode) {
                case AlertMode.PriceDrop:
                    return "Price Drop";
                case AlertMode.PriceIncrease:
                    return "Price Increase";
                case AlertMode.AnyChangeInPrice:
                    return "Any Change";
            }
        };
        const GetPriceRestrictionIcon = (mode: AlertMode) => {
            switch (mode) {
                case AlertMode.PriceDrop:
                    return trendingDown;
                case AlertMode.PriceIncrease:
                    return trendingUp;
                case AlertMode.AnyChangeInPrice:
                    return statsChart;
            }
        };
        const GetPriceRestrictionColor = (mode: AlertMode) => {
            switch (mode) {
                case AlertMode.PriceDrop:
                    return "success";
                case AlertMode.PriceIncrease:
                    return "danger";
                case AlertMode.AnyChangeInPrice:
                    return "warning";
            }
        };

        // Edit Alert
        const OpenModal = async (modal: any) => {
            const controller = await modalController.create({
                component: modal,
                componentProps: {
                    product: props.product,
                },
            });
            await controller.present();
            controller.onDidDismiss().then((didUserSave) => {
                console.log(didUserSave);
                if (didUserSave.data) {
                    emit("ProductEditedEvent");
                }
            });
        };

        const Delete = () => {
            emit("ProductDeletedEvent");
            SniperAPI.post("dashboard/DeleteAlert?id=" + props.product.AlertID).then(function (
                response
            ) {
                if (!response.data.success) {
                    toast("An error occured. Please try again later.");
                } else {
                    emit("ProductEditedEvent");
                }
            });
        };

        const HandleSnoozeDismissedEvent = () => {
            Activate()
            toast("Snooze dismissed.")
        }

        const Activate = () => {
            SniperAPI.post("dashboard/ActivateAlert?AlertId=" + props.product.AlertID).then(function (
                response
            ) {
                if (!response.data.success) {
                    toast("An error occured. Please try again later.");
                } else {
                    emit("ProductEditedEvent");
                }
            });
        }

        const Open = () => {
            console.log("open");
            console.log(slidingItem.value);
            slidingItem.value.$el.open();
        }

        const Close = () => {
            console.log("close");
            slidingItem.value.$el.close();
        }


        return {
            power,
            share,
            trash,
            window,
            openOutline,
            toUSD,
            GetPriceRestrictionText,
            GetPriceRestrictionIcon,
            GetPriceRestrictionColor,
            trendingDown,
            trendingUp,
            statsChart,
            moon,
            closeCircle,
            OpenModal,
            EditProductModal,
            trashOutline,
            createOutline,
            Delete,
            slidingItem,
            Open,
            Close,
            Activate,
            HandleSnoozeDismissedEvent
        };
    },
    props: {
        vueID: Number,
        product: {
            required: true,
            type: Object as PropType<DashboardProduct>,
        },
    },
});
</script>