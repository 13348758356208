import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            (_ctx.cancelButton)
              ? (_openBlock(), _createBlock(_component_ion_buttons, {
                  key: 0,
                  slot: "start"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_button, { color: "medium" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.cancelButton), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.title), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modalController.dismiss()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.confirmButton), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { class: "ion-padding" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data, (item) => {
              return (_openBlock(), _createBlock(_component_ion_item, {
                key: item[_ctx.itemIdKey]
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_checkbox, {
                    "label-placement": "end",
                    justify: "start",
                    modelValue: item[_ctx.itemStateKey],
                    "onUpdate:modelValue": ($event: any) => ((item[_ctx.itemStateKey]) = $event),
                    onClick: ($event: any) => (_ctx.updateCheckboxOnClick(item))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item[_ctx.itemDisplayKey]), 1)
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue", "onClick"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}