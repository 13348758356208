import axios from "axios";
import router from "../router/index";
import { useUserStore } from "@/store";

export const SniperAPI = buildinstance();

function buildinstance() {
    const instance = axios.create({
        withCredentials: true,
        baseURL: process.env.VUE_APP_API_HOST
    });

    // Add a request interceptor to modify the request before it's sent
    instance.interceptors.request.use(
        (config) => {
            // You can modify the request config here if needed
            // For example, add an authentication token if the user is logged in

            // const token = localStorage.getItem('token');
            // if (token) {
            //   config.headers.Authorization = `Bearer ${token}`;
            // }

            // 
            if (window.location.pathname === "/login" && config.url === "/join/GetStripePublishableKey")
                return Promise.reject("Abort config until user logins in.");

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        if (typeof error !== "string" && "response" in error){
            if (error.response.status === 401) {
                // Redirect to the login page
                router.push("login");
            }
            if (error.response.status === 402 && window.location.pathname !== "/join/plan") {
                // Always hide menue here
                let u = useUserStore();
                u.setUserIsLoggedIn(false);
                // Redirect to the plan page
                router.push("/join/plan");
            }    
        }
        return Promise.reject(error);
    });

    return instance;
} 