import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9f394c70"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-container" }
const _hoisted_2 = { class: "row product-row ion-margin-top" }
const _hoisted_3 = { class: "left" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "right" }
const _hoisted_6 = { class: "row details-row" }
const _hoisted_7 = { class: "left" }
const _hoisted_8 = { class: "right" }
const _hoisted_9 = { class: "row ion-margin-top chip-row" }
const _hoisted_10 = { class: "left chip-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_SnoozedChip = _resolveComponent("SnoozedChip")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ShippingChip = _resolveComponent("ShippingChip")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_item_option = _resolveComponent("ion-item-option")!
  const _component_ion_item_options = _resolveComponent("ion-item-options")!
  const _component_ion_item_sliding = _resolveComponent("ion-item-sliding")!

  return (_openBlock(), _createBlock(_component_ion_item_sliding, { ref: "slidingItem" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item, {
        detail: true,
        button: "",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.OpenModal(_ctx.EditProductModal)))
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["flex-container", { 'disabled-product': !_ctx.product.IsActive }])
          }, [
            _createVNode(_component_ion_img, {
              class: "product-image",
              alt: _ctx.product.ProductName,
              src: _ctx.product.PictureLink,
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.window.open(_ctx.product.CardLink, '_blank')), ["stop"]))
            }, null, 8, ["alt", "src"]),
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("a", {
                    onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"])),
                    href: _ctx.product.CardLink,
                    target: "_blank",
                    class: "hoverable product-hyperlink"
                  }, [
                    _createVNode(_component_ion_text, null, {
                      default: _withCtx(() => [
                        _createElementVNode("strong", null, _toDisplayString(_ctx.product.ProductName), 1),
                        _createTextVNode(" - " + _toDisplayString(_ctx.product.GroupName) + " ", 1),
                        _createVNode(_component_ion_icon, {
                          class: "appear-on-hover",
                          icon: _ctx.openOutline
                        }, null, 8, ["icon"])
                      ]),
                      _: 1
                    })
                  ], 8, _hoisted_4)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("strong", null, _toDisplayString(_ctx.toUSD(_ctx.product.DesiredPrice)), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_ion_text, { color: "medium" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.product.ConditionName), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_8, [
                  (_ctx.product.CurrentPrice)
                    ? (_openBlock(), _createBlock(_component_ion_text, {
                        key: 0,
                        color: "medium"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.toUSD(_ctx.product.CurrentPrice)), 1)
                        ]),
                        _: 1
                      }))
                    : (_openBlock(), _createBlock(_component_ion_text, {
                        key: 1,
                        color: "medium"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(" out of stock ")
                        ]),
                        _: 1
                      }))
                ])
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  (_ctx.product.IsSnoozed())
                    ? (_openBlock(), _createBlock(_component_SnoozedChip, {
                        key: 0,
                        product: _ctx.product,
                        onSnoozeDismissedEvent: _cache[2] || (_cache[2] = ($event: any) => (_ctx.HandleSnoozeDismissedEvent()))
                      }, null, 8, ["product"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_chip, {
                    class: "price-mode-chip",
                    style: {"background":"none !important"},
                    outline: true,
                    color: "medium"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        icon: _ctx.GetPriceRestrictionIcon(_ctx.product.PriceRestriction),
                        color: _ctx.GetPriceRestrictionColor(_ctx.product.PriceRestriction)
                      }, null, 8, ["icon", "color"]),
                      _createVNode(_component_ion_label, { class: "price-mode-label" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.GetPriceRestrictionText(_ctx.product.PriceRestriction)), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ShippingChip, {
                    IsShippingIncluded: _ctx.product.IsShippingIncluded
                  }, null, 8, ["IsShippingIncluded"])
                ])
              ])
            ])
          ], 2)
        ]),
        _: 1
      }),
      _createVNode(_component_ion_item_options, {
        onIonSwipe: _cache[6] || (_cache[6] = ($event: any) => (_ctx.Delete())),
        side: "end"
      }, {
        default: _withCtx(() => [
          (_ctx.product.IsActive === false)
            ? (_openBlock(), _createBlock(_component_ion_item_option, {
                key: 0,
                color: "success",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.Activate()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.power }, null, 8, ["icon"]),
                  _createTextVNode("  Activate ")
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_ion_item_option, {
            color: "danger",
            expandable: "",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.Delete()))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { icon: _ctx.trashOutline }, null, 8, ["icon"]),
              _createTextVNode("  Delete ")
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512))
}