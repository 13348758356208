<template>
  <!-- Disabled Segment -->
  <ion-segment disabled :value="step">
    <ion-segment-button :value="StepCount.One">
      <ion-label
        >1<br />
        Enter URL</ion-label
      >
    </ion-segment-button>
    <ion-segment-button :value="StepCount.Two">
      <ion-label>2<br />Select Condition</ion-label>
    </ion-segment-button>
    <ion-segment-button :value="StepCount.Three">
      <ion-label>3<br />Set Price</ion-label>
    </ion-segment-button>
  </ion-segment>
</template>
<style scoped>
.segment-button-disabled {
  opacity: initial;
}
</style>
<script lang="ts">
import { IonSegment, IonSegmentButton, IonLabel } from "@ionic/vue";
import { defineComponent, PropType, watch } from "vue";
import { toast } from "../utils/SimpleToast.js";
import { searchOutline } from "ionicons/icons";
import { StepCount } from "@/models/StepCountManager";

export default defineComponent({
  components: {
    IonSegment,
    IonSegmentButton,
    IonLabel,
  },
  setup() {
    return { searchOutline, StepCount };
  },
  props: {
    step: {
      default: StepCount.One,
      required: true,
      type: String as PropType<StepCount>,
    },
  },
});
</script>
