<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div id="container">
        <ion-img
          class="landing-page-logo"
          src="https://cdn.tcgsniper.com/TCGSniperLogo.svg"
        ></ion-img>
        <div class="card">
          <ion-grid>
            <ion-row class="ion-justify-content-center" v-if="disableForm">
              <ion-col offset="0" offset-md="0" size="12" size-md="6">
                <PageNote
                :icon="mailOutline"
                text="Success! Check your inbox for for further instructions."
              />
              </ion-col>
            </ion-row>
            <ion-row v-if="disableForm === false && hasValidToken == false">
              <ion-col offset="0" offset-md="3" size="12" size-md="6">
                <ion-item>
                  <ion-input
                    label="Email"
                    label-placement="floating"
                    @keyup.enter="Submit()"
                    v-model="email"
                    type="email"
                  ></ion-input>
                </ion-item>
                <ion-button
                  v-if="isBusy"
                  size="large"
                  class="loginbtn"
                  expand="block"
                  disabled
                >
                  <ion-spinner name="circular"></ion-spinner>
                  &nbsp; &nbsp; {{ GetTitle() }}</ion-button
                >
                <ion-button
                  v-else
                  @click="Submit()"
                  size="large"
                  class="loginbtn"
                  expand="block"
                  >{{ GetTitle() }}</ion-button
                >

                <ion-button class="loginbtn" fill="clear" router-link="/login"
                  >Login</ion-button
                >
                <ion-button class="loginbtn" fill="clear" router-link="/join"
                  >Sign Up</ion-button
                >
              </ion-col>
            </ion-row>
            <ion-row v-if="hasValidToken">
              <ion-col offset="0" offset-md="3" size="12" size-md="6">
                <ion-item class="ion-margin-bottom">
                  <ion-input
                    label="New Password"
                    label-placement="floating"
                    @keyup.enter="ResetPass()"
                    v-model="pass"
                    type="password"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-input
                    label="Confirm Password"
                    label-placement="floating"
                    @keyup.enter="ResetPass()"
                    v-model="passConfirm"
                    type="password"
                  ></ion-input>
                </ion-item>
                <ion-item v-if="newPassErrors.length > 0" lines="none">
                  <div v-for="(err, index) in newPassErrors" :key="index">
                    <ion-text color="danger">{{ err }}</ion-text>
                  </div>
                </ion-item>
                <ion-button
                  v-if="isBusy"
                  size="large"
                  class="loginbtn"
                  expand="block"
                  disabled
                >
                  <ion-spinner name="circular"></ion-spinner>
                  &nbsp; &nbsp; {{ GetTitle() }}</ion-button
                >
                <ion-button
                  v-else
                  @click="ResetPass()"
                  size="large"
                  class="loginbtn"
                  expand="block"
                  >{{ GetTitle() }}</ion-button
                >

                <ion-button class="loginbtn" fill="clear" router-link="/login"
                  >Login</ion-button
                >
                <ion-button class="loginbtn" fill="clear" router-link="/join"
                  >Sign Up</ion-button
                >
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonImg,
  IonPage,
  IonSpinner,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonButton,
  IonText,
} from "@ionic/vue";
import { LoginFactory, ILoginManager } from "@/utils/LoginManager";
import PageNote from "../components/PageNote.vue";
import { defineComponent, onMounted, ref } from "vue";
import { toast } from "@/utils/SimpleToast";
import { useRoute } from "vue-router";
import { useIonRouter } from "@ionic/vue";
import { mailOutline } from "ionicons/icons";
import { SniperAPI } from "@/utils/AxiosInstance";
import { AxiosResponse } from "axios";

export default defineComponent({
  components: {
    IonContent,
    IonImg,
    IonPage,
    IonSpinner,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonInput,
    IonButton,
    PageNote,
    IonText,
  },
  setup() {
    const email = ref("");
    const pass = ref("");
    const passConfirm = ref("");
    let isBusy = ref(false);
    let isPageBusy = ref(false);
    let disableForm = ref(false);
    let hasValidToken = ref(false);
    const loginManager: ILoginManager = LoginFactory.GetLoginManager();
    const router = useRoute();
    const ionRouter = useIonRouter();
    const token: any = router.query["token"];
    const newPassErrors = ref<string[]>([]);

    const IsTokenValid = () => {
      isPageBusy.value = true;
      SniperAPI.get("/validate-reset-token", { params: { token } })
        .then(function (response) {
          if (response.data.success) {
            hasValidToken.value = true;
          }
        })
        .finally(function () {
          isPageBusy.value = false;
        });
    };
    const GetTitle = () => {
      if (hasValidToken.value) {
        return "Reset Password";
      }
      return "Request Reset Link";
    };
    const Submit = () => {
      isBusy.value = true;

      loginManager
        .RequestResetToken(email.value)
        .then((success) => {
          disableForm.value = success;
        })
        .catch(() => {
          // Handle catch
          toast("An unknown error occured. Please try again later.");
        })
        .finally(() => {
          isBusy.value = false;
        });
    };
    const ResetPass = () => {
      newPassErrors.value.length = 0;

      if (pass.value !== passConfirm.value) {
        newPassErrors.value = ["Passwords do not match."];
        return;
      }

      let [success, errs] = loginManager.PasswordMeetsRequirements(pass.value);

      if (!success) {
        newPassErrors.value = errs;
        return;
      }

      isBusy.value = true;
      loginManager
        .ResetPassword(pass.value, token)
        .then(function (redirect) {
          if (redirect) {
            pass.value = "";
            passConfirm.value = "";
            // do redirect
            ionRouter.push("Dashboard");
          }
        })
        .catch(function () {
          toast("An unknown error occured.");
        })
        .finally(function () {
          isBusy.value = false;
        });
    };
    onMounted(() => {
      IsTokenValid();
    });
    return {
      email,
      pass,
      passConfirm,
      Submit,
      isBusy,
      disableForm,
      hasValidToken,
      GetTitle,
      ResetPass,
      newPassErrors,
      mailOutline,
    };
  },
});
</script>

<style scoped>
.loginitem {
}
.loginbtn {
  margin-top: 30px;
}
.title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 50px;
}
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}
ion-item {
  font-size: 20px;
}
</style>
