import { AlertMode } from "@/utils/Enums";
import TCGPlayerPriceModel from "./TCGPlayerPriceModel";

export class DashboardProduct {
    AlertID: number;
    CardLink: string;
    ConditionName: string;
    CreatedDate: string;
    CurrentPrice: number;
    DesiredPrice: number;
    GroupName: string;
    IsActive: boolean;
    IsShippingIncluded: boolean;
    PictureLink: string;
    PriceRestriction: AlertMode;
    ProductName: string;
    TCGPlayerGroupID: number;
    TCGPlayerProductID: number;
    UserID: number;
    Sku: string;
    SnoozedUntil: Date|null;
    SnoozedUntilLocalTime: string;
    LastTriggered: Date|null;

    constructor(obj?: any) {
        this.AlertID = 0;
        this.CardLink = "";
        this.ConditionName = "";
        this.CreatedDate = "";
        this.CurrentPrice = 0;
        this.DesiredPrice = 0;
        this.GroupName = "";
        this.IsActive = false;
        this.IsShippingIncluded = true;
        this.PictureLink = "";
        this.PriceRestriction = 0;
        this.ProductName = "";
        this.TCGPlayerGroupID = 0;
        this.TCGPlayerProductID = 0;
        this.UserID = 0;
        this.Sku = "";
        this.SnoozedUntil = null;
        this.SnoozedUntilLocalTime = "";
        this.LastTriggered = null;
        
        if (obj){
            this.AlertID = obj.alertID;
            this.CardLink = obj.cardLink;
            this.ConditionName = obj.conditionName;
            this.CreatedDate = obj.creationDate;
            this.CurrentPrice = obj.currentPrice;
            this.DesiredPrice = obj.desiredPrice;
            this.GroupName = obj.groupName;
            this.IsActive = obj.isActive;
            this.IsShippingIncluded = obj.isShippingIncluded;
            this.PictureLink = obj.pictureLink;
            this.PriceRestriction = obj.priceRestriction;
            this.ProductName = obj.productName;
            this.TCGPlayerGroupID = obj.tcgPlayerGroupID;
            this.TCGPlayerProductID = obj.tcgPlayerProductID;
            this.UserID = obj.UserID;
            this.Sku = obj.conditionID;
            this.SnoozedUntil = obj.snoozedUntil == null ? obj.snoozedUntil : new Date(obj.snoozedUntil); 
            this.SnoozedUntilLocalTime = obj.snoozedUntil == null ? obj.snoozedUntil : new Date(obj.snoozedUntil + "UTC");//adding UTC converts to local time
            this.LastTriggered = obj.lastTriggered;
        }
    }  
    IsSnoozed (): boolean {
        const currentDate = new Date();
        if (this.SnoozedUntil && this.SnoozedUntil > currentDate) {
            return this.SnoozedUntil > currentDate;
        }
        return false;
    }
}

export class ImportedProduct {
    categoryId: number; 
    cleanName: string;
    groupId: number;
    groupName: string;
    imageUrl: string;
    modifiedOn: Date;
    name: string;
    productId: number;
    skus: TCGPlayerSKU[];
    url: string;

    /**
     *
     */
    constructor() {
        this.categoryId = 0;
        this.cleanName = "";
        this.groupId = 0;
        this.groupName = "";
        this.imageUrl = "";
        this.modifiedOn = new Date();
        this.name = "";
        this.productId = 0;
        this.skus = [];
        this.url = "";
    }
}

export class TCGPlayerSKU {
    skuId: string;
    productId: number;
    languageId: number;
    languageName: string;
    languageAbbreviation: string;
    printingId: number;
    conditionId: number;
    conditionName: string;
    //dropDownDisplayName: string;
    conditionAbbreviation: string;

    /**
     *
     */
    constructor() {
        this.skuId = "";
        this.productId = 0;
        this.languageId = 0;
        this.languageName = "";
        this.languageAbbreviation = ""; 
        this.printingId = 0;
        this.conditionId = 0;
        this.conditionName = "";
        //this.dropDownDisplayName = "";
        this.conditionAbbreviation = "";
        
    }
}

export class SelectableTCGPlayerSKU extends TCGPlayerSKU {
    isChecked: boolean;
    price: TCGPlayerPriceModel;

    constructor() {
        super();
        this.isChecked = false;
        this.price = new TCGPlayerPriceModel();
    }
}
// TCGPlayer specific... for now
export class NewProductAlertViewModel {
    ProductId: number;
    ProductName: string;
    GroupId: string;
    GroupName: string;
    PictureLink: string;
    CardLink: string;
    DesiredPrice: string;
    PriceRestriction: AlertMode;
    Skus: SelectableTCGPlayerSKU[];
    IsShippingIncluded: boolean;

    constructor() {
        this.ProductId = 0;
        this.ProductName = "";
        this.GroupId = "";
        this.GroupName = "";
        this.PictureLink = "";
        this.CardLink = "";
        this.DesiredPrice = "";
        this.PriceRestriction = AlertMode.PriceDrop;
        this.Skus = [];
        this.IsShippingIncluded = true;
    }

}

export class PaginatedDashboardProductResult {
    alerts: DashboardProduct[] = [];
    pageNumber: number;
    pageSize: number;
    totalPages: number;

    constructor() {
        this.pageNumber = 0;
        this.pageSize = 0;
        this.totalPages = 0;
    }
}