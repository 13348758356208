import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6a355df1"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = {
  style: {"font-size":"1.7rem"},
  class: "ion-margin-top-0 ion-margin-bottom-0 font-weight-bold"
}
const _hoisted_4 = { class: "ion-margin-top-0" }
const _hoisted_5 = { class: "ion-margin-start" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_PageNote = _resolveComponent("PageNote")!
  const _component_ImportStepCounter = _resolveComponent("ImportStepCounter")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ImportProduct = _resolveComponent("ImportProduct")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ImportSkuSelector = _resolveComponent("ImportSkuSelector")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_card_title = _resolveComponent("ion-card-title")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_AlertTypeInput = _resolveComponent("AlertTypeInput")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { color: "primary" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Create Price Alert")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, { collapse: "condense" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, { size: "large" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Create Price Alert")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_PageNote, { text: "Create a new alert to be notified\n      when a product reaches a price desired range." }),
          _createVNode(_component_ion_item, {
            lines: "none",
            style: {"margin-bottom":"32px"}
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ImportStepCounter, { step: _ctx.step }, null, 8, ["step"])
            ]),
            _: 1
          }),
          _createVNode(_component_ImportProduct, {
            onProductImportedEvent: _cache[0] || (_cache[0] = ($event: any) => (_ctx.HandleProductImportedEvent($event))),
            onBusyStateChangedEvent: _cache[1] || (_cache[1] = ($event: any) => (_ctx.HandleBusyStateChangeEvent($event))),
            onException: _cache[2] || (_cache[2] = ($event: any) => (_ctx.ResetForm())),
            class: "ion-margin-bottom"
          }),
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-margin-top" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    "size-md": "6",
                    size: "12"
                  }, {
                    default: _withCtx(() => [
                      (!_ctx.hideProduct)
                        ? (_openBlock(), _createBlock(_component_ion_row, { key: 0 }, {
                            default: _withCtx(() => [
                              (_ctx.product.url)
                                ? (_openBlock(), _createBlock(_component_ion_col, {
                                    key: 0,
                                    "size-md": "4",
                                    size: "12",
                                    class: "product-image-container"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("a", {
                                        href: _ctx.product.url,
                                        target: "_blank"
                                      }, [
                                        _createElementVNode("img", {
                                          class: "product-image",
                                          src: _ctx.product.imageUrl,
                                          alt: _ctx.product.cleanName
                                        }, null, 8, _hoisted_2)
                                      ], 8, _hoisted_1)
                                    ]),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_ion_col, {
                                    key: 1,
                                    class: "ion-justify-content-center ion-align-items-center",
                                    style: {"display":"flex","min-height":"100px"},
                                    "size-md": "4",
                                    size: "12"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_spinner, {
                                        class: "ion-rt",
                                        color: "primary"
                                      })
                                    ]),
                                    _: 1
                                  })),
                              _createVNode(_component_ion_col, {
                                "size-md": "8",
                                size: "12",
                                class: "product-data"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.product.cleanName), 1),
                                  _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.product.groupName), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  (_ctx.step !== _ctx.StepCount.One)
                    ? (_openBlock(), _createBlock(_component_ion_col, {
                        key: 0,
                        "size-md": "6",
                        size: "12"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_row, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_col, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ImportSkuSelector, {
                                    onSkuSelectionEdited: _cache[3] || (_cache[3] = ($event: any) => (_ctx.HandleSkuSelectionEditedEvent($event))),
                                    skus: _ctx.product.skus,
                                    hint: _ctx.conditionsHintText
                                  }, null, 8, ["skus", "hint"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_row, { class: "ion-margin-top" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, null, {
                    default: _withCtx(() => [
                      (_ctx.step !== _ctx.StepCount.One)
                        ? (_openBlock(), _createBlock(_component_ion_card, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_card_header, { style: {"background-color":"var(--ion-color-light)"} }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_card_title, null, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_text, {
                                        style: {"font-size":"30px","position":"relative","top":"7px"},
                                        class: "tcgplayer"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_ion_icon, { icon: _ctx.analytics }, null, 8, ["icon"])
                                        ]),
                                        _: 1
                                      }),
                                      _createTextVNode(" Configure TCGPlayer Alert ")
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }),
                              (_ctx.step !== _ctx.StepCount.Three)
                                ? (_openBlock(), _createBlock(_component_ion_row, {
                                    key: 0,
                                    class: "ion-margin-bottom"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("h4", _hoisted_5, [
                                        _createVNode(_component_ion_text, { color: "warning" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_icon, { icon: _ctx.warning }, null, 8, ["icon"])
                                          ]),
                                          _: 1
                                        }),
                                        _createTextVNode(" You must select a condition to configure an alert ")
                                      ])
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              _createElementVNode("div", {
                                class: _normalizeClass(_ctx.step == _ctx.StepCount.Two ? 'invisible' : 'visible')
                              }, [
                                _createVNode(_component_ion_row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_col, null, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_item, { lines: "none" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_text, {
                                              color: "medium",
                                              slot: "start"
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode("Condition")
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_ion_text, {
                                              color: "medium",
                                              slot: "end"
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode("Lowest Listing")
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pricingTableSkus, (sku) => {
                                          return (_openBlock(), _createBlock(_component_ion_item, {
                                            key: sku.skuId
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_ion_label, null, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(sku.conditionName), 1)
                                                ]),
                                                _: 2
                                              }, 1024),
                                              (_ctx.IsShippingIncluded)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                                    (sku.price.lowestListingPrice < 0)
                                                      ? (_openBlock(), _createBlock(_component_ion_text, {
                                                          key: 0,
                                                          slot: "end"
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_ion_spinner)
                                                          ]),
                                                          _: 1
                                                        }))
                                                      : _createCommentVNode("", true),
                                                    (sku.price.lowestListingPrice == 0)
                                                      ? (_openBlock(), _createBlock(_component_ion_text, {
                                                          key: 1,
                                                          color: "medium",
                                                          slot: "end"
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode("out of stock")
                                                          ]),
                                                          _: 1
                                                        }))
                                                      : _createCommentVNode("", true),
                                                    (sku.price.lowestListingPrice > 0)
                                                      ? (_openBlock(), _createBlock(_component_ion_text, {
                                                          key: 2,
                                                          class: "price",
                                                          slot: "end"
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.toUSD(sku.price.lowestListingPrice)), 1)
                                                          ]),
                                                          _: 2
                                                        }, 1024))
                                                      : _createCommentVNode("", true)
                                                  ]))
                                                : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                                                    (sku.price.lowPrice < 0)
                                                      ? (_openBlock(), _createBlock(_component_ion_text, {
                                                          key: 0,
                                                          slot: "end"
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createVNode(_component_ion_spinner)
                                                          ]),
                                                          _: 1
                                                        }))
                                                      : _createCommentVNode("", true),
                                                    (sku.price.lowPrice == 0)
                                                      ? (_openBlock(), _createBlock(_component_ion_text, {
                                                          key: 1,
                                                          color: "medium",
                                                          slot: "end"
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode("out of stock")
                                                          ]),
                                                          _: 1
                                                        }))
                                                      : _createCommentVNode("", true),
                                                    (sku.price.lowPrice > 0)
                                                      ? (_openBlock(), _createBlock(_component_ion_text, {
                                                          key: 2,
                                                          class: "price",
                                                          slot: "end"
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.toUSD(sku.price.lowPrice)), 1)
                                                          ]),
                                                          _: 2
                                                        }, 1024))
                                                      : _createCommentVNode("", true)
                                                  ]))
                                            ]),
                                            _: 2
                                          }, 1024))
                                        }), 128)),
                                        _createVNode(_component_ion_item, { lines: "none" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_icon, {
                                              class: _normalizeClass(["shipping-excluded ion-margin-left-0", {
                          'shipping-excluded-nested': !_ctx.IsShippingIncluded,
                        }]),
                                              slot: "end",
                                              style: {"fontsize":"20px"},
                                              icon: _ctx.banOutline
                                            }, null, 8, ["class", "icon"]),
                                            _createVNode(_component_ion_icon, {
                                              slot: "end",
                                              style: {"fontsize":"20px"},
                                              class: "ion-margin-left-0",
                                              src: `${_ctx.publicPath}fonts/truck-solid.svg`
                                            }, null, 8, ["src"]),
                                            (_ctx.IsShippingIncluded)
                                              ? (_openBlock(), _createBlock(_component_ion_text, {
                                                  key: 0,
                                                  class: "ion-margin-left-0",
                                                  color: "medium",
                                                  slot: "end"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode("  shipping included")
                                                  ]),
                                                  _: 1
                                                }))
                                              : (_openBlock(), _createBlock(_component_ion_text, {
                                                  key: 1,
                                                  class: "ion-margin-left-0",
                                                  color: "medium",
                                                  slot: "end"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode("  shipping excluded")
                                                  ]),
                                                  _: 1
                                                }))
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_col, {
                                      size: "12",
                                      "size-md": "6"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_item, { lines: "none" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_label, {
                                              position: "fixed",
                                              for: "desired-price-input"
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode("Set Price")
                                              ]),
                                              _: 1
                                            })
                                          ]),
                                          _: 1
                                        }),
                                        _createVNode(_component_ion_item, {
                                          class: _normalizeClass({
                        'ion-invalid': !_ctx.isDesiredPriceInputValid,
                        'invalid-item': !_ctx.isDesiredPriceInputValid,
                      })
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_icon, {
                                              style: {"fontsize":"20px"},
                                              slot: "start",
                                              src: `${_ctx.publicPath}fonts/dollar-sign-solid.svg`
                                            }, null, 8, ["src"]),
                                            _createVNode(_component_ion_input, {
                                              id: "desired-price-input",
                                              placeholder: "5.00",
                                              inputmode: "decimal",
                                              type: "number",
                                              value: "number",
                                              modelValue: _ctx.desiredPriceInput,
                                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.desiredPriceInput) = $event))
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        }, 8, ["class"]),
                                        _createVNode(_component_ion_item, { lines: "none" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_text, { slot: "end" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode("include shipping")
                                              ]),
                                              _: 1
                                            }),
                                            _createVNode(_component_ion_toggle, {
                                              class: "ion-margin-start",
                                              modelValue: _ctx.IsShippingIncluded,
                                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.IsShippingIncluded) = $event)),
                                              slot: "end"
                                            }, null, 8, ["modelValue"])
                                          ]),
                                          _: 1
                                        })
                                      ]),
                                      _: 1
                                    }),
                                    _createVNode(_component_ion_col, {
                                      size: "12",
                                      "size-md": "6"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_AlertTypeInput, {
                                          InitialAlertType: _ctx.AlertMode.PriceDrop,
                                          onAlertTypeChangedEvent: _cache[6] || (_cache[6] = ($event: any) => (
                        _ctx.HandleAlertTypeChangedEvent($event)
                      ))
                                        }, null, 8, ["InitialAlertType"])
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                }),
                                _createVNode(_component_ion_row, { class: "ion-margin-top ion-margin-bottom" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_ion_col, null, {
                                      default: _withCtx(() => [
                                        (!_ctx.desiredPriceComputed)
                                          ? (_openBlock(), _createBlock(_component_ion_button, {
                                              key: 0,
                                              disabled: "",
                                              size: "large",
                                              color: "success",
                                              expand: "block"
                                            }, {
                                              default: _withCtx(() => [
                                                _createTextVNode("Save")
                                              ]),
                                              _: 1
                                            }))
                                          : (_openBlock(), _createBlock(_component_ion_button, {
                                              key: 1,
                                              onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.SaveAlert())),
                                              disabled: _ctx.IsSaveBusy,
                                              size: "large",
                                              color: "success",
                                              expand: "block",
                                              style: {"white-space":"normal"}
                                            }, {
                                              default: _withCtx(() => [
                                                (_ctx.IsSaveBusy)
                                                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, [
                                                      _createTextVNode(" Saving...   "),
                                                      _createVNode(_component_ion_spinner, { class: "" })
                                                    ]))
                                                  : (_openBlock(), _createElementBlock("span", _hoisted_9, " Save alert for " + _toDisplayString(_ctx.desiredPriceComputed), 1))
                                              ]),
                                              _: 1
                                            }, 8, ["disabled"]))
                                      ]),
                                      _: 1
                                    })
                                  ]),
                                  _: 1
                                })
                              ], 2)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}