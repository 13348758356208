<template>
  <ion-page>
    <ion-header :translucent="true"> </ion-header>

    <ion-content :fullscreen="true">
      <div id="container">
        <ion-img
          class="landing-page-logo"
          src="https://cdn.tcgsniper.com/TCGSniperLogo.svg"
        ></ion-img>
        <div class="card">
          <ion-grid>
            <ion-row>
              <ion-col offset="0" offset-md="3" size="12" size-md="6">
                <ion-item class="ion-margin-bottom">
                  <ion-input
                  label="Email"
                  label-placement="floating"
                    @keyup.enter="Login()"
                    v-model="email"
                    type="email"
                  ></ion-input>
                </ion-item>
                <ion-item>
                  <ion-input
                    label="Password"
                    label-placement="floating"
                    @keyup.enter="Login()"
                    v-model="pass"
                    type="password"
                  ></ion-input>
                </ion-item>
                <ion-button
                  v-if="isBusy"
                  size="large"
                  class="loginbtn"
                  expand="block"
                  disabled
                >
                  <ion-spinner name="circular"></ion-spinner>
                  &nbsp; &nbsp; Login</ion-button
                >
                <ion-button
                  v-else
                  @click="Login()"
                  size="large"
                  class="loginbtn"
                  expand="block"
                  >Login</ion-button
                >

                <ion-button
                  class="loginbtn"
                  fill="clear"
                  router-link="/reset-password"
                  >Forgot password</ion-button
                >
                <ion-button router-link="/join" class="loginbtn" fill="clear"
                  >Sign Up</ion-button
                >
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonContent,
  IonHeader,
  IonPage,
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonButton,
  IonSpinner,
  IonImg,
  IonGrid,
} from "@ionic/vue";
import { LoginFactory, ILoginManager } from "@/utils/LoginManager";
import { defineComponent, ref } from "vue";
import { toast } from "@/utils/SimpleToast";

export default defineComponent({
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonInput,
    IonButton,
    IonSpinner,
    IonImg,
  },
  setup() {
    const email = ref("");
    const pass = ref("");
    let isBusy = ref(false);
    const loginManager: ILoginManager = LoginFactory.GetLoginManager();

    const Login = () => {
      isBusy.value = true;

      loginManager
        .Login(email.value, pass.value)
        .then((isLoggedIn) => {
          // do something?
        })
        .catch(() => {
          // Handle catch
          toast("An unknown error occured. Please try again later.");
        })
        .finally(() => {
          isBusy.value = false;
        });
    };
    return {
      email,
      pass,
      Login,
      isBusy,
    };
  },
});
</script>

<style scoped>
.loginitem {
}
.loginbtn {
  margin-top: 30px;
}
.title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 50px;
}
#card ion-col {
  margin-left: auto;
  margin-right: auto;
}
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}
ion-item {
  font-size: 20px;
}
</style>
