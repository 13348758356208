import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_alert = _resolveComponent("ion-alert")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_item, {
      id: "present-logout",
      button: "",
      lines: "none",
      class: "hydrated"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_icon, {
          slot: "start",
          ios: _ctx.arrowBack,
          md: _ctx.arrowBack
        }, null, 8, ["ios", "md"]),
        _createVNode(_component_ion_label, null, {
          default: _withCtx(() => [
            _createTextVNode("Log Out")
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_alert, {
      trigger: "present-logout",
      header: "Confirm Log Out",
      buttons: _ctx.alertButtons,
      onDidDismiss: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logResult($event)))
    }, null, 8, ["buttons"])
  ], 64))
}