<template>
  <ion-chip :outline="true" class="shipping-chip-container" color="medium">
    <ion-icon class="shipping-excluded ion-margin-left-0" :class="{
      'shipping-excluded-nested': !IsShippingIncluded,
    }" style="font-size: 30px" :icon="banOutline">
    </ion-icon>
    <ion-icon style="font-size: 20px" class="truck ion-margin-left-0" :src="`${publicPath}fonts/truck-solid.svg`">
    </ion-icon>
    <ion-label style="text-overflow: ellipsis;" v-if="IsShippingIncluded">Shipping Included</ion-label>
    <ion-label v-else>Shipping Excluded</ion-label>
  </ion-chip>
</template>
<script lang="ts">
import { IonChip, IonIcon, IonLabel } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { toast } from "../utils/SimpleToast.js";
import { banOutline } from "ionicons/icons";

export default defineComponent({
  components: {
    IonChip,
    IonIcon,
    IonLabel,
  },
  setup() {
    const publicPath = process.env.BASE_URL;
    return { banOutline, publicPath };
  },
  props: {
    IsShippingIncluded: Boolean,
  },
});
</script>
<style scoped>
.shipping-chip-container {
  position: relative;
  cursor: initial;
}

ion-label {
  white-space: nowrap;
}

.ion-color:hover {
  background: none !important;
}

.truck {
  margin-right: 8px;
}

.shipping-excluded {
  display: hidden;
  visibility: none;
  width: 25%;
  opacity: 0;
  position: absolute;
  margin-top: 0px;
  top: 1px;
  left: 16px;
  rotate: -90deg;
  transition: left 230ms ease-in-out, visibility 230ms ease-in-out,
    opacity 230ms ease-in-out, rotate 230ms ease-in-out;
  font-size: 225%;
  color: rgba(217, 83, 79, 1);
  z-index: 2;
}

.shipping-excluded-nested {
  display: inline;
  opacity: 1;
  top: 1px;
  left: 0px;
  rotate: initial;
}
</style>
