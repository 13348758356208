<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-img class="logo ion-margin-start" src="https://cdn.tcgsniper.com/TCGSniperLogo.svg"></ion-img>
        <ion-button slot="end" fill="clear" router-link="/login">LOG IN</ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-grid class="ion-h-100 ion-padding-0">
        <ion-row class="column-wrapper ion-h-100">
          <ion-col size-xs="12" size-lg="6">
            <SupportedCardsSwiper></SupportedCardsSwiper>
          </ion-col>
          <ion-col class="ion-padding-bottom-0 ion-h-100 card-container" size-xs="12" size-lg="6">
            <div class="card">
              <h1 class="fancy-title ion-padding-top ion-padding-start">
                Create an account
              </h1>
              <ion-row>
                <ion-col>
                  <ion-item id="name">

                    <ion-input label="Name" label-placement="floating" v-model="name" autocomplete="name"
                      :required="true"></ion-input>
                  </ion-item>
                  <ion-item id="email">
                    <ion-input label="Email" label-placement="floating" v-model="email" type="email" inputmode="email"
                      autocomplete="email" pattern=""></ion-input>
                  </ion-item>
                  <ion-item id="phone">
                    <ion-input label="Phone" label-placement="floating" v-model="phone" type="tel" inputmode="tel"
                      autocomplete="tel" helper-text="">
                    </ion-input>
                    <ion-button id="PhoneHelper" size="large" fill="clear" slot="end"
                      style="margin-top: 7px; margin-bottom: 0px">
                      <ion-icon :icon="helpCircleOutline"></ion-icon>
                    </ion-button>
                    <ion-popover trigger="PhoneHelper" trigger-action="hover" size="auto">
                      <ion-content class="ion-padding" style="line-height: 1.5">
                        <p style="font-weight: bold">
                          Why do you ask for this?
                        </p>
                        <p>
                          You have the option to receive your alerts via
                          SMS/text. We will never use your phone number for
                          marketing purposes. This field is completely optional.
                        </p>
                      </ion-content>
                    </ion-popover>
                  </ion-item>
                  <ion-note class="ion-margin-start">Optional</ion-note>
                  <ion-item id="password">
                    <ion-input label="Password" label-placement="floating" v-model="password"
                      type="password"></ion-input>
                  </ion-item>
                  <ion-item id="repassword">
                    <ion-input label="Confirm Password" label-placement="floating" v-model="repassword"
                      type="password"></ion-input>
                  </ion-item>
                  <ion-item id="terms" lines="none">
                    <ion-checkbox label-placement="end" v-model="acceptedTerms" slot="start">
                      I agree to the
                      <a v-on:click.stop="" style="display: inline-flex; align-items: center"
                        href="https://tcgsniper.com/terms" target="_blank">terms of
                        service
                        <ion-icon style="padding-left: 5px" :icon="logOutOutline"></ion-icon></a>
                    </ion-checkbox>
                  </ion-item>
                  <ion-item v-if="signUpErrs.length > 0" lines="none">
                    <div v-for="(err, index) in signUpErrs" :key="index">
                      <ion-text color="danger">{{ err }}</ion-text>
                    </div>
                  </ion-item>
                  <p class="ion-text-end"></p>
                  <ion-button @click="SignUp()" :disabled="isBusy" class="loginbtn" expand="block" size="large">Register
                    Now
                    <ion-spinner v-if="isBusy" class="ion-margin-start" slot="end"></ion-spinner>

                  </ion-button>
                  <hr />
                  <p class="ion-text-center">
                    Already have an existing account?
                  </p>
                  <div class="ion-text-center">
                    <ion-button size="small" color="primary" fill="outline" router-link="/login">Log In
                      Here</ion-button>
                  </div>
                  <p class="ion-text-center">Need help?</p>
                  <div class="ion-text-center">
                    <ion-button size="small" color="primary" fill="outline" href="mailto:help@tcgsniper.com">
                      Email us
                    </ion-button>
                  </div>

                </ion-col>
              </ion-row>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonNote,
  IonImg,
  IonButton,
  IonContent,
  IonHeader,
  IonPage,
  IonToolbar,
  IonRow,
  IonCol,
  IonItem,
  IonInput,
  IonIcon,
  IonGrid,
  IonCheckbox,
  IonText,
  IonPopover,
  IonSpinner,
} from "@ionic/vue";
import {
  LoginFactory,
  ILoginManager,
  SignUpResponse,
} from "@/utils/LoginManager";
import { defineComponent, ref } from "vue";
import { toast } from "@/utils/SimpleToast";
import { logOutOutline, helpCircleOutline } from "ionicons/icons";
import router from "../router/index";
import SupportedCardsSwiper from "@/components/SupportedCardsSwiper.vue";

export default defineComponent({
  components: {
    IonNote,
    IonImg,
    IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar,
    IonRow,
    IonCol,
    IonIcon,
    IonItem,
    IonInput,
    IonCheckbox,
    IonGrid,
    IonText,
    IonPopover,
    IonSpinner,
    SupportedCardsSwiper,
  },
  setup() {
    const name = ref<string>("");
    const email = ref<string>("");
    const password = ref<string>("");
    const repassword = ref<string>("");
    const phone = ref<string>("");
    const acceptedTerms = ref<boolean>(false);
    const signUpErrs = ref<string[]>([]);
    const loginManager: ILoginManager = LoginFactory.GetLoginManager();
    const cssErrorClasses = ["invalid-item", "ion-invalid"];
    const isBusy = ref<boolean>(false);

    const SignUp = () => {
      try {
        isBusy.value = true;

        if (ValidateInput() == false) {
          isBusy.value = false;
          return;
        }

        // Create user
        loginManager
          .SignUp(
            name.value,
            email.value,
            password.value,
            phone.value,
            acceptedTerms.value
          )
          .then((response: SignUpResponse) => {
            if (response.success) {
              toast("Welcome 🤘");
              router.push("/join/plan");
            } else {
              signUpErrs.value = response.errors;
            }
          })
          .catch((e) => {
            // Handle catch
            console.error(e);
            toast("An unknown error occured. Please try again later.");
          })
          .finally(() => {
            // Handle busy state
            isBusy.value = false;
            // console.log(
            //  name.value,
            //  email.value,
            //  password.value,
            //  phone.value,
            //  acceptedTerms.value
            //);
          });
      } catch (error) {
        console.error(error);
      }
    };

    const ValidateInput = (): boolean => {
      signUpErrs.value.length = 0;
      // Remove spaces
      name.value = name.value.trim();
      email.value = email.value.replace(/\s/g, "");

      if (name.value.length == 0) {
        document.getElementById("name")?.classList.add(...cssErrorClasses);
        return false;
      } else {
        document.getElementById("name")?.classList.remove(...cssErrorClasses);
      }

      let ex = new RegExp("@.*\\.")

      if (email.value.length == 0 || ex.test(email.value) == false) {
        document.getElementById("email")?.classList.add(...cssErrorClasses);
        return false;
      } else {
        document.getElementById("email")?.classList.remove(...cssErrorClasses);
      }

      if (password.value !== repassword.value) {
        signUpErrs.value = ["Passwords do not match."];
        return false;
      }

      if (acceptedTerms.value == false) {
        document.getElementById("terms")?.classList.add(...cssErrorClasses);
        return false;
      } else {
        document.getElementById("terms")?.classList.remove(...cssErrorClasses);
      }

      // Validate new password
      let [success, errs] = loginManager.PasswordMeetsRequirements(
        password.value
      );

      if (!success) {
        document.getElementById("password")?.classList.add(...cssErrorClasses);
        document
          .getElementById("repassword")
          ?.classList.add(...cssErrorClasses);
        // do something with errs
        signUpErrs.value = errs;
        return false;
      } else {
        document
          .getElementById("password")
          ?.classList.remove(...cssErrorClasses);
        document
          .getElementById("repassword")
          ?.classList.remove(...cssErrorClasses);
      }
      return true;
    };

    return {
      logOutOutline,
      helpCircleOutline,
      name,
      email,
      password,
      repassword,
      phone,
      acceptedTerms,
      signUpErrs,
      SignUp,
      isBusy,
    };
  },
});
</script>

<style scoped>
.column-wrapper {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../components/images/TCGSniperAlertCollage.png");
  background-size: cover;
}

.card {
  height: 95%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: var(--ion-background-color);
  box-shadow: 10px 5px 5px black;
}

@media only screen and (max-width: 992px) {
  .card {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: none;
  }

  .card-container {
    padding: 0;
  }
}

.logo {
  max-width: 150px;
}

.loginitem {}

.loginbtn {
  margin-top: 30px;
}

.fancy-title {
  margin-bottom: 30px;
  border-bottom: 2px solid var(--ion-color-primary);
  padding-bottom: 10px;
  color: #444;
  font-weight: 600;
  line-height: 1.5;
}

.fancy-word {
  color: var(--ion-color-primary);
}

#container {}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}

ion-item {
  font-size: 20px;
}

.loginbtn {
  margin-top: 30px;
}
</style>
