import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!

  return (_openBlock(), _createBlock(_component_ion_chip, {
    outline: true,
    class: "shipping-chip-container",
    color: "medium"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_icon, {
        class: _normalizeClass(["shipping-excluded ion-margin-left-0", {
      'shipping-excluded-nested': !_ctx.IsShippingIncluded,
    }]),
        style: {"font-size":"30px"},
        icon: _ctx.banOutline
      }, null, 8, ["class", "icon"]),
      _createVNode(_component_ion_icon, {
        style: {"font-size":"20px"},
        class: "truck ion-margin-left-0",
        src: `${_ctx.publicPath}fonts/truck-solid.svg`
      }, null, 8, ["src"]),
      (_ctx.IsShippingIncluded)
        ? (_openBlock(), _createBlock(_component_ion_label, {
            key: 0,
            style: {"text-overflow":"ellipsis"}
          }, {
            default: _withCtx(() => [
              _createTextVNode("Shipping Included")
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_ion_label, { key: 1 }, {
            default: _withCtx(() => [
              _createTextVNode("Shipping Excluded")
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}