<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ion-toolbar>
        <ion-buttons slot="primary">
          <ion-button size="large" style="font-weight: bold" @click="HandleModalDismiss()">
            <ion-icon :icon="closeOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title class="" size="small">
          {{ GenPageNote() }}
        </ion-title>
      </ion-toolbar>
      <ion-list>
        <ion-item lines="none">
          <ion-label>Current price</ion-label>
          <ion-label style slot="end">{{ CurrentPriceComputed > 0 ? "$" + CurrentPriceComputed
            :
            "out of stock" }}</ion-label>
        </ion-item>
        <ion-item aria-hidden="true" lines="none">
          <ion-label aria-hidden="true">Desired price</ion-label>
        </ion-item>
        <ion-item line="none">
          <br>
          <ion-icon slot="start" :icon="logoUsd" aria-hidden="true"></ion-icon>
          <ion-input aria-label="Desired Price" inputmode="decimal" type="number" v-model="desiredPriceInput"
            placeholder="5.00" :autofocus="true">
          </ion-input>
        </ion-item>
        <ion-item lines="none">
          <ion-text slot="end">Include shipping </ion-text>
          <ion-toggle class="ion-margin-start" v-model="editProduct.IsShippingIncluded" slot="end"></ion-toggle>
        </ion-item>
        <AlertTypeInput :InitialAlertType="editProduct.PriceRestriction"
          @AlertTypeChangedEvent="HandleAlertTypeChangedEvent($event)"></AlertTypeInput>
        <ion-item v-if="product.IsSnoozed() && unSnoozeComplete == false" lines="none">
          <ion-label>Active
            <p color="medium">
              <ion-icon color="snoozed" :icon="moon"></ion-icon>
              snoozed until {{ product.SnoozedUntil?.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' }) }}
            </p>
          </ion-label>
          <ion-button @click="Activate()" color="primary" slot="end" size="small">

            Resume now</ion-button>
        </ion-item>
        <ion-item v-else lines="none">
          <ion-toggle v-model="editProduct.IsActive">
            <ion-label>Active</ion-label>
            <ion-note color="medium">
              price checking
            </ion-note>
          </ion-toggle>
        </ion-item>

        <ion-button class="ion-margin" :disabled="isBtnDisabled" @click="UpdateAlert()" color="primary" expand="block"
          size="large">
          <ion-spinner v-if="isBtnBusy" name="crescent"></ion-spinner>
          <span v-if="isBtnBusy">&nbsp;</span>
          Save
        </ion-button>

      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonPage,
  IonContent,
  IonToolbar,
  IonButtons,
  IonButton,
  IonList,
  IonItem,
  IonInput,
  IonIcon,
  IonTitle,
  IonText,
  IonToggle,
  IonSpinner,
  IonLabel,
  IonNote,
  modalController,
} from "@ionic/vue";
import { computed, defineComponent, PropType, reactive, ref } from "vue";
import { closeOutline, checkmarkCircleOutline, logoUsd, moon } from "ionicons/icons";
import { DashboardProduct } from "@/models/Product";
import { SniperAPI } from "@/utils/AxiosInstance";
import TCGPlayerPriceModel from "@/models/TCGPlayerPriceModel";

import { successToast, toast } from "@/utils/SimpleToast.js";
import { AlertMode } from "@/utils/Enums";
import AlertTypeInput from "@/components/AlertTypeInput.vue";

export default defineComponent({
  name: "EditProductModal",
  components: {
    IonPage,
    IonContent,
    IonToolbar,
    IonButtons,
    IonButton,
    IonList,
    IonItem,
    IonInput,
    IonIcon,
    IonTitle,
    IonText,
    IonToggle,
    IonSpinner,
    AlertTypeInput,
    IonLabel,
    IonNote,
  },
  setup(props, { emit }) {
    //Data declarations (previously data{} in vue 2)

    const editProduct = reactive({
      DesiredPrice: props.product.DesiredPrice,
      IsShippingIncluded: props.product.IsShippingIncluded,
      PriceRestriction: props.product.PriceRestriction,
      PreviousDesiredPrice: props.product.DesiredPrice,
      IsActive: props.product.IsActive
    });

    const desiredPriceInput = ref(editProduct.DesiredPrice.toString());
    const isPriceBusy = ref(true);
    const isBtnBusy = ref(false);
    const productEdited = ref(false);
    const unSnoozeComplete = ref(false);
    const publicPath = process.env.BASE_URL;

    const Activate = () => {
      SniperAPI.post("dashboard/ActivateAlert?AlertId=" + props.product.AlertID).then(function (
        response
      ) {
        if (!response.data.success) {
          toast("An error occured. Please try again later.");
        } else {
          unSnoozeComplete.value = true;
          emit("ProductEditedEvent");
        }
      });
    }

    const UpdateAlert = () => {
      isBtnBusy.value = true;
      let payload = {
        alertId: props.product.AlertID,
        conditionID: props.product.Sku,
        DesiredPrice: 0,
        PreviousDesiredPrice: editProduct.PreviousDesiredPrice,
        IsShippingIncluded: editProduct.IsShippingIncluded,
        PriceRestriction: editProduct.PriceRestriction,
        IsActive: editProduct.IsActive
      };
      if (editProduct.PriceRestriction == AlertMode.AnyChangeInPrice) {
        //If shipping is included, use the lowest listing price. Otherwise, use the low price.
        if (editProduct.IsShippingIncluded) {
          payload.DesiredPrice = price.lowestListingPrice !== null ? price.lowestListingPrice : 0;
        }
        else {
          payload.DesiredPrice = price.lowPrice !== null ? price.lowPrice : 0;
        }
      }
      else {
        //Need to enforce two decimal points, but toFixed returns a string?? So, cast back to number before attaching to payload.
        const formattedPrice = parseFloat(desiredPriceInput.value).toFixed(2);
        desiredPriceInput.value = formattedPrice;
        payload.DesiredPrice = parseFloat(formattedPrice);
      }

      SniperAPI.post("dashboard/EditAlert", payload)
        .then(function (response: any) {
          isBtnBusy.value = false;
          if (response.data.success) {
            productEdited.value = true;
            successToast("Changes saved");
            HandleModalDismiss();
          } else {
            toast(response.data.error.toString());
          }
        })
        .catch(function (error) {
          isBtnBusy.value = false;
          toast("Failed to save changes");
          console.error("Save error:", error);
        });
    }


    const price = reactive<TCGPlayerPriceModel>({
      skuId: "0",
      lowestListingPrice: 0,
      lowPrice: 0,
    });

    const GetCurrentPriceFromAPI = (sku: any) => {
      isPriceBusy.value = true;

      SniperAPI.get(`alert/GetCardPrices?id=${sku}`)
        .then(function (response: any) {
          price.skuId = sku;
          price.lowestListingPrice = !response.data.results[0].lowestListingPrice ? 0 : response.data.results[0].lowestListingPrice;
          price.lowPrice = response.data.results[0].lowPrice ? 0 : response.data.results[0].lowPrice;

          isPriceBusy.value = false;
        })
        .catch(function () {
          toast("An error occured.");
        });
    };

    const CurrentPriceComputed = computed(() => {
      if (editProduct.IsShippingIncluded) {
        return price.lowestListingPrice;
      }
      return price.lowPrice;
    });

    const GenPageNote = () => {
      return `${props.product.ProductName} - ${props.product.GroupName} (${props.product.ConditionName})`;
    };
    const GenHelpText = () => {
      switch (editProduct.PriceRestriction) {
        case AlertMode.PriceDrop:
          return "Notify me when the lowest listing price is at or below ....";
        case AlertMode.PriceIncrease:
          return "Notify me when the lowest listing price is at or above ....";
        case AlertMode.AnyChangeInPrice:
          return `Notify me when the lowest listing price changes from the current price.`;
      }
    };

    const HandleAlertTypeChangedEvent = (mode: AlertMode) => {
      editProduct.PriceRestriction = mode;

      if (mode == AlertMode.AnyChangeInPrice) {
        desiredPriceInput.value = "0.00";
      }
    };

    const isBtnDisabled = computed(() => {
      if (!desiredPriceInput.value) {
        if (editProduct.PriceRestriction != AlertMode.AnyChangeInPrice) return true;
        return false;
      }
      if (isBtnBusy.value == true) return true;
      if (parseFloat(desiredPriceInput.value).toFixed(2) == "0.00") return true;

      return false;
    });


    const HandleModalDismiss = () => {
      // Always pass the productEdited state when dismissing
      // This ensures proper refresh regardless of how modal is closed
      modalController.dismiss(productEdited.value).catch((error) => {
        console.error("Error dismissing modal:", error);
      });
    };

    return {
      editProduct,
      modalController,
      closeOutline,
      desiredPriceInput,
      GetCurrentPriceFromAPI,
      CurrentPriceComputed,
      isPriceBusy,
      AlertMode,
      GenPageNote,
      isBtnDisabled,
      HandleAlertTypeChangedEvent,
      HandleModalDismiss,
      isBtnBusy,
      checkmarkCircleOutline,
      publicPath,
      logoUsd,
      moon,
      GenHelpText,
      Activate,
      UpdateAlert,
      unSnoozeComplete
    };
  },
  mounted() {
    this.GetCurrentPriceFromAPI(this.product.Sku);
  },
  props: {
    product: {
      required: true,
      type: Object as PropType<DashboardProduct>,
    },
  },
});
</script>
<style scoped>
.form-footer {
  bottom: 2rem;
  width: 100%;
  min-height: 56px;
  margin-bottom: 3rem;
}

.header {
  font-weight: bold;
}

.edit-current-price {
  font-size: smaller;
  color: var(--ion-color-medium);
  --min-height: 30px !important;
}

.help-text {
  --min-height: 30px;
}

.icon-price-decrease {
  color: var(--ion-color-success);
}

.icon-price-increase {
  color: var(--ion-color-danger);
}

.icon-any-change-in-price {
  color: var(--ion-color-warning);
}
</style>