import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';
import {createPinia} from 'pinia';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import './theme/custom.css';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

/* Plugins */
import {SniperAPI} from  "./utils/AxiosInstance";
import * as FullStory from "@fullstory/browser";
import Hotjar from '@hotjar/browser';
import { Crisp } from 'crisp-sdk-web';
import VueVirtualScroller from 'vue-virtual-scroller'


declare global {
  interface Window {_STRIPE_PUBLISHABLE_KEY: string, _FULLSTORY_INSTANCE: any}
}

const app = createApp(App)
  .use(IonicVue, {
    mode: 'md'
  })
  .use(router)
  .use(createPinia())
  .use(VueVirtualScroller);

// FullStory install 
// FullStory.init({orgId: process.env.VUE_APP_FULLSTORY_ORG_ID});
// window._FULLSTORY_INSTANCE = FullStory;

// Hotjar install
const siteId = 3834814;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

// Crisp Live Chat
Crisp.configure(process.env.VUE_APP_CRISP_CHAT_WEBSITE_ID);
  
router.isReady().then(() => {
  app.mount('#app');
    SniperAPI.get("/join/GetStripePublishableKey")
    .then(
      (response) => {
        window._STRIPE_PUBLISHABLE_KEY = response.data.results;
    }
    ).catch((error) =>{
      if (error === "Abort config until user logins in.")
      {
        console.warn(error);
      }
    })
}
);