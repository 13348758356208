<template>
  <ion-app>
    <ion-split-pane :disabled="false" content-id="main-content">
      <ion-menu content-id="main-content" type="overlay" :class="{ 'ion-hide': !userStore.isLoggedin }">
        <ion-content>
          <ion-list id="inbox-list">
            <ion-img router-link="/" class="logo clickable" :src="logo"></ion-img>
            <!--TODO<ion-note>email@domain.com</ion-note>-->
            <ion-menu-toggle :auto-hide="!userStore.isLoggedin">
              <ion-item v-for="(p, i) in appPages" :key="i" button router-direction="root" :router-link="p.url"
                lines="none" :detail="false" class="hydrated" :class="{ selected: selectedIndex === i }">
                <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
              <SidebarLogout />
            </ion-menu-toggle>

          </ion-list>
          <ion-list id="labels-list">
            <ion-list-header>Need something?</ion-list-header>

            <ion-item button v-for="(label, index) in helpLabels" @click="selectedIndex = index" :href="label.url"
              target="_blank" lines="none" :key="index">
              <ion-icon slot="start" :ios="label.iosIcon" :md="label.mdIcon"></ion-icon>
              <ion-label class="ion-text-wrap">{{ label.title }}</ion-label>
            </ion-item>

            <ion-item class="this-week-in-mtg" button
              href="https://tcgsniper.com/help/tcgsniper-featured-on-the-this-week-in-mtg-podcast/" target="_blank"
              lines="none">
              <ion-icon class="this-week-in-mtg-icon" slot="start" :ios="mic" :md="micSharp"></ion-icon>
              <ion-label class="ion-text-wrap">As seen on: This Week in MTG</ion-label>
            </ion-item>
          </ion-list>
        </ion-content>
      </ion-menu>
      <ion-router-outlet :animated="true" id="main-content"></ion-router-outlet>
    </ion-split-pane>
  </ion-app>
</template>

<script lang="ts">
import {
  IonApp,
  IonContent,
  IonImg,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonSplitPane,
} from "@ionic/vue";
import { defineComponent, ref, computed, watch } from "vue";
import { useRoute } from "vue-router";
import {
  speedometerOutline,
  speedometerSharp,
  alertOutline,
  alertSharp,
  settingsOutline,
  settingsSharp,
  archiveOutline,
  archiveSharp,
  bookmarkOutline,
  bookmarkSharp,
  heartOutline,
  heartSharp,
  mailOutline,
  bookOutline,
  paperPlaneOutline,
  paperPlaneSharp,
  trashOutline,
  trashSharp,
  warningOutline,
  warningSharp,
  arrowBack,
  micSharp,
  mic,
  rocketOutline
} from "ionicons/icons";
import SidebarLogout from "@/components/SidebarLogout.vue";
import { IsAuthenticated } from "./utils/helpers";
import { useUserStore } from "@/store";

export default defineComponent({
  name: "App",
  components: {
    IonApp,
    IonContent,
    IonImg,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonRouterOutlet,
    IonSplitPane,
    SidebarLogout,
  },
  setup() {
    const userStore = useUserStore();
    const selectedIndex = ref(0);
    const logoNormal = "https://cdn.tcgsniper.com/TCGSniperLogo.svg";
    const logoDark = "https://cdn.tcgsniper.com/TCGSniperLogoDark.svg";
    let logo = logoNormal;

    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      logo = logoDark;
    }

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      const newColorScheme = event.matches ? "dark" : "light";

      if (newColorScheme == "dark") {
        logo = logoDark;
      }
      else {
        logo = logoNormal;
      }
    });
    const appPages = [
      {
        title: "Dashboard",
        url: "/dashboard",
        iosIcon: speedometerOutline,
        mdIcon: speedometerSharp,
      },
      {
        title: "Create Alert",
        url: "/alert",
        iosIcon: alertOutline,
        mdIcon: alertSharp,
      },
      {
        title: "Account Settings",
        url: "/settings",
        iosIcon: settingsOutline,
        mdIcon: settingsSharp,
      },
    ];
    const helpLabels = [
      {
        title: "Catch Some Zzz’s with Alert Snoozing 😴",
        url: "https://tcgsniper.com/help/catch-some-zzzs-with-alert-snoozing/",
        iosIcon: rocketOutline,
        mdIcon: rocketOutline,
      },
      {
        title: "help@tcgsniper.com",
        url: "mailto:help@tcgsniper",
        iosIcon: mailOutline,
        mdIcon: mailOutline,
      },
      {
        title: "Help Center",
        url: "https://tcgsniper.com/help/",
        iosIcon: bookOutline,
        mdIcon: bookOutline,
      },
    ];


    const route = useRoute();

    // Navbar logic

    const navBarController = () => {
      if (route.path !== undefined) {
        selectedIndex.value = appPages.findIndex(
          (page) => page.url.toLowerCase() === route.path.toLowerCase()
        );
      }
    }

    watch(route, async () => {
      navBarController();
    })

    return {
      selectedIndex,
      appPages,
      helpLabels,
      archiveOutline,
      archiveSharp,
      bookmarkOutline,
      bookmarkSharp,
      heartOutline,
      heartSharp,
      mailOutline,
      paperPlaneOutline,
      paperPlaneSharp,
      trashOutline,
      trashSharp,
      warningOutline,
      warningSharp,
      userStore,
      arrowBack,
      logo,
      mic,
      micSharp,
      //checkCookie,
      navBarController,
      //isSelected: (url: string) => (url === route.path ? "selected" : ""),
    };
  },
  beforeMount() {
    // Sometimes Pinia and local storage forgets 🤷‍♂️
    this.userStore.isLoggedin = IsAuthenticated();

    this.navBarController()
  },
});
</script>

<style scoped>
.this-week-in-mtg {
  border: 1px;
  font-style: italic;
}

.this-week-in-mtg-icon {
  color: rgb(16, 104, 47) !important;
}

.logo {
  width: 250px;
  margin-left: 5px;
  margin-right: auto;
  margin-bottom: 15px;
}

@media only screen and (max-width: 991px) {
  .logo {
    max-width: 75%;
    margin-left: 5px;
    margin-right: auto;
  }
}

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: var(--ion-color-medium);
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>
