import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!

  return (_openBlock(), _createBlock(_component_ion_item, { lines: "none" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_label, { class: "page-note" }, {
        default: _withCtx(() => [
          (_ctx.icon !== undefined)
            ? (_openBlock(), _createBlock(_component_ion_icon, {
                key: 0,
                class: "page-note-icon",
                slot: "start",
                icon: _ctx.icon
              }, null, 8, ["icon"]))
            : _createCommentVNode("", true),
          _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}