export enum AlertMode {
    PriceDrop = 0,
    AnyChangeInPrice = 1,
    PriceIncrease = 2
}

export enum PlanName {
  BASE = "BASE",
  PLUS = "PLUS"
}

// fullstory.d.ts (create this file in your project)
import FullStory from '@fullstory/browser';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $FullStory: typeof FullStory;
  }
}
