import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ProductItem = _resolveComponent("ProductItem")!
  const _component_PaginationButtons = _resolveComponent("PaginationButtons")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, { translucent: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { color: "primary" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { color: "tertiary" })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, {
                slot: "end",
                "router-link": "/alert"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    slot: "end",
                    size: "default",
                    color: "tertiary",
                    fill: "solid",
                    shape: "round"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, { icon: _ctx.add }, null, 8, ["icon"]),
                      _createTextVNode("   New Alert ")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Dashboard")
                ]),
                _: 1
              }),
              _withDirectives(_createVNode(_component_ion_progress_bar, {
                color: "tertiary",
                type: "indeterminate"
              }, null, 512), [
                [_vShow, _ctx.isLoading]
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_list, { lines: "none" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, { class: "header-container" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_segment, {
                    color: "primary",
                    class: "dashboard-toggle",
                    modelValue: _ctx.dashboardMode,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dashboardMode) = $event))
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_segment_button, {
                        value: "active",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.GetAlerts(1)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Active (" + _toDisplayString(_ctx.alertCounts.active) + "/" + _toDisplayString(_ctx.alertCounts.max) + ")", 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_segment_button, {
                        value: "inactive",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.GetAlerts(1)))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_label, null, {
                            default: _withCtx(() => [
                              _createTextVNode("Disabled (" + _toDisplayString(_ctx.alertCounts.inactive) + ")", 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  (_ctx.dashboard.alerts.length > 0)
                    ? _withDirectives((_openBlock(), _createBlock(_component_ion_button, {
                        key: 0,
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isEditMode = !_ctx.isEditMode)),
                        slot: "end",
                        size: "default",
                        shape: "round",
                        fill: "clear"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            slot: "icon-only",
                            size: "small",
                            icon: _ctx.pencil
                          }, null, 8, ["icon"])
                        ]),
                        _: 1
                      }, 512)), [
                        [_vShow, !_ctx.isLoading]
                      ])
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          (_ctx.hasAlerts === false && _ctx.isLoading == false)
            ? (_openBlock(), _createBlock(_component_ion_grid, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      (_ctx.dashboardMode == 'active')
                        ? (_openBlock(), _createBlock(_component_ion_label, {
                            key: 0,
                            color: "medium"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" You don't have any active alerts right now. ")
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_label, {
                            key: 1,
                            color: "medium"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(" You don't have any disabled alerts right now. ")
                            ]),
                            _: 1
                          }))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_button, {
                        "router-link": "/alert",
                        expand: "block",
                        size: "medium"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Create new alert")
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _withDirectives(_createVNode(_component_ion_searchbar, {
              modelValue: _ctx.searchText,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.searchText) = $event)),
              debounce: 500,
              placeholder: "Search Alerts"
            }, null, 8, ["modelValue"]), [
              [_vShow, _ctx.alertCounts.active > 50 && _ctx.dashboardMode == 'active' || _ctx.alertCounts.inactive > 50 && _ctx.dashboardMode == 'inactive']
            ]),
            (_ctx.dashboard.alerts.length > 0)
              ? (_openBlock(), _createBlock(_component_ion_list, {
                  key: 0,
                  style: {"margin-left":"0px","margin-right":"0px"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dashboard.alerts, (product) => {
                      return (_openBlock(), _createBlock(_component_ProductItem, {
                        key: product.AlertID,
                        id: product.AlertID,
                        product: product,
                        ref_for: true,
                        ref: "slidingItems",
                        onProductEditedEvent: _cache[5] || (_cache[5] = ($event: any) => (_ctx.HandleEditProduct())),
                        onProductDeletedEvent: ($event: any) => (_ctx.HandleProductDeleted(product.AlertID))
                      }, null, 8, ["id", "product", "onProductDeletedEvent"]))
                    }), 128))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_ion_list, { style: {"margin-bottom":"80px"} }, {
              default: _withCtx(() => [
                (_ctx.dashboard.totalPages > 1)
                  ? (_openBlock(), _createBlock(_component_PaginationButtons, {
                      key: 0,
                      CanGoBackward: _ctx.CanGoBackward(),
                      PagePickerBusy: _ctx.PagePickerBusy,
                      CanGoForward: _ctx.CanGoForward(),
                      PageNumber: _ctx.dashboard.pageNumber,
                      PageCount: _ctx.dashboard.totalPages,
                      BackwardBusy: _ctx.BackwardBusy,
                      ForwardBusy: _ctx.ForwardBusy,
                      onGoBackward: _cache[6] || (_cache[6] = ($event: any) => (_ctx.HandleGoBackward())),
                      onGoForward: _cache[7] || (_cache[7] = ($event: any) => (_ctx.HandleGoForward())),
                      onGoToPage: _cache[8] || (_cache[8] = ($event: any) => {_ctx.PagePickerBusy = true; _ctx.GetAlerts($event, _ctx.searchText)})
                    }, null, 8, ["CanGoBackward", "PagePickerBusy", "CanGoForward", "PageNumber", "PageCount", "BackwardBusy", "ForwardBusy"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}