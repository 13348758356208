<template>
  <ion-item>
    <ion-input
      label="TCGPlayer.com URL"
      label-placement="stacked"
      v-model="rawURL"
      placeholder="https://shop.tcgplayer.com/magic/war-of-the-spark/fblthp-the-lost"
      :clear-input="true"
      @keyup.enter="ProcessInput()"
    ></ion-input>
    <ion-button v-if="isBusy" disabled slot="end" size="medium">
      <ion-spinner></ion-spinner>
    </ion-button>
    <ion-button v-else @click="ProcessInput()" slot="end" size="medium">
      <ion-icon :icon="searchOutline"></ion-icon>
      <span class="ion-hide-md-down">Import</span>
    </ion-button>
  </ion-item>
</template>
<script lang="ts">
import {
  IonItem,
  IonInput,
  IonButton,
  IonIcon,
  IonSpinner,
  alertController,
} from "@ionic/vue";
import { defineComponent, ref, reactive, watch } from "vue";
import { toast } from "../utils/SimpleToast.js";
import { searchOutline } from "ionicons/icons";
import { SniperAPI } from "@/utils/AxiosInstance.js";

import { ImportedProduct } from "@/models/Product";
import SniperResponse from "@/models/SniperResponse";

export default defineComponent({
  components: {
    IonItem,
    IonInput,
    IonButton,
    IonIcon,
    IonSpinner,
  },
  props: {},
  emits: ["ProductImportedEvent", "BusyStateChangedEvent", "Exception"],
  setup(props, { emit }) {
    const isBusy = ref(false);
    const rawURL = ref("");
    const extractedProduct = reactive({
      rawPath: "",
      categoryName: "",
      groupName: "",
      productName: "",
      productId: "",
      isLegacyURL: false,
    });

    const presentAlert = async (
      header: string,
      subHeader: string,
      message: string,
      buttons: string[]
    ) => {
      const alert = await alertController.create({
        cssClass: "my-custom-class",
        header: header,
        subHeader: subHeader,
        message: message,
        buttons: buttons,
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    const ProcessInput = () => {
      isBusy.value = true;
      //In the future this should be server side
      extractedProduct.isLegacyURL = false;

      if (!isValidURL()) {
        isBusy.value = false;
        return;
      }
      extractedProduct.categoryName = "";
      extractedProduct.groupName = "";
      extractedProduct.productName = "";
      extractedProduct.productId = "";

      //Extract useful parts of the URL to perform look up
      if (extractedProduct.isLegacyURL) {
        let str = extractedProduct.rawPath.split("/");
        //ignore 0
        extractedProduct.categoryName = str[1];
        extractedProduct.groupName = str[2];
        extractedProduct.productName = str[3];
      } else {
        let str = extractedProduct.rawPath.split("/");
        extractedProduct.productId = str[2];
      }
      SniperAPI.get<SniperResponse>(
        `alert/GetCardInfo?categoryName=${extractedProduct.categoryName}&groupName=${extractedProduct.groupName}&productName=${extractedProduct.productName}&productId=${extractedProduct.productId}`
      )
        .then(function (response) {
          let ip = new ImportedProduct();
          ip = response.data.results;
          //rawURL.value = "";

          emit("ProductImportedEvent", ip);
        })
        .catch(function () {
          emit("Exception");
          presentAlert(
            "Almost there...",
            "",
            "We couldn't find the product you wanted to import with this URL. Please provide a URL to a single product and try again.",
            ["OK"]
          );
        })
        .finally(function () {
          isBusy.value = false;
        });
    };

    const isValidURL = () => {
      let parser = document.createElement("a");

      parser.href = rawURL.value;

      if (parser.hostname.toLowerCase().includes("tcgplayer.com")) {
        //Ensure the path isn't something other than a product URL. More cases may be added in the future.

        if (parser.pathname.includes("/search/")) {
          presentAlert(
            "Almost there...",
            "",
            "We couldn't find the product you wanted to import with this URL. Please provide a URL to a single product and try again.",
            ["Try again"]
          );
          return false;
        }

        //Check for legacy URL
        if (
          parser.hostname.toLowerCase() === "shop.tcgplayer.com" ||
          parser.hostname.toLowerCase() === "store.tcgplayer.com"
        ) {
          extractedProduct.isLegacyURL = true;
        } else {
          extractedProduct.isLegacyURL = false;
        }
        extractedProduct.rawPath = parser.pathname;
        return true;
      } else {
        presentAlert(
          "Dangnabit!",
          "Invalid URL",
          "Please import a valid TCGPlayer.com URL",
          ["Try another URL"]
        );
        return false;
      }
    };

    watch(isBusy, async () => {
      emit("BusyStateChangedEvent", isBusy.value);
    });

    return { searchOutline, rawURL, ProcessInput, isBusy };
  },
});
</script>
<style></style>
