<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <div id="container">
        <h1>✌</h1>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/vue";
import { defineComponent } from "@vue/runtime-core";
import { LoginFactory } from "@/utils/LoginManager";

export default defineComponent({
  components: {
    IonContent,
    IonPage,
  },
  setup() {
    const loginManager = LoginFactory.GetLoginManager();
    const Logout = () => {
      loginManager.Logout();
    };

    return {
      Logout,
    };
  },
  mounted() {
    this.Logout();
  },
});
</script>

<style scoped>
.container {
  text-align: center;
}
</style>
