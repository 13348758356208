import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-d8691362"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pagination-button-group" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_action_sheet = _resolveComponent("ion-action-sheet")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ion_button, {
      disabled: !_ctx.CanGoBackward || _ctx.BackwardBusy,
      onClick: _ctx.GoBackward,
      color: "primary",
      slot: "end",
      class: "back-button"
    }, {
      default: _withCtx(() => [
        (_ctx.BackwardBusy)
          ? (_openBlock(), _createBlock(_component_ion_spinner, {
              key: 0,
              class: "spinner",
              name: "crescent"
            }))
          : (_openBlock(), _createBlock(_component_ion_icon, {
              key: 1,
              icon: _ctx.chevronBackOutline
            }, null, 8, ["icon"]))
      ]),
      _: 1
    }, 8, ["disabled", "onClick"]),
    _createVNode(_component_ion_button, {
      disabled: _ctx.PagePickerBusy,
      class: "ion-padding-start ion-padding-end",
      fill: "clear",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setOpen(true)))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Page  "),
        (_ctx.PagePickerBusy)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createVNode(_component_ion_spinner, {
                class: "spinner",
                name: "crescent"
              })
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.PageNumber), 1)),
        _createVNode(_component_ion_icon, {
          class: "ion-padding-start",
          icon: _ctx.chevronDown
        }, null, 8, ["icon"])
      ]),
      _: 1
    }, 8, ["disabled"]),
    _createVNode(_component_ion_button, {
      disabled: !_ctx.CanGoForward || _ctx.ForwardBusy,
      onClick: _ctx.GoForward,
      color: "primary",
      slot: "end",
      class: "forward-button"
    }, {
      default: _withCtx(() => [
        (_ctx.ForwardBusy)
          ? (_openBlock(), _createBlock(_component_ion_spinner, {
              key: 0,
              class: "spinner",
              name: "crescent"
            }))
          : (_openBlock(), _createBlock(_component_ion_icon, {
              key: 1,
              icon: _ctx.chevronForwardOutline
            }, null, 8, ["icon"]))
      ]),
      _: 1
    }, 8, ["disabled", "onClick"]),
    _createVNode(_component_ion_action_sheet, {
      "is-open": _ctx.isOpen,
      header: "Jump to page",
      buttons: _ctx.actionSheetButtons,
      onDidDismiss: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setOpen(false)))
    }, null, 8, ["is-open", "buttons"])
  ]))
}