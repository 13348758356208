<template>
    <div class="pagination-button-group">
        <ion-button :disabled="!CanGoBackward || BackwardBusy" @click="GoBackward" color="primary" slot="end"
            class="back-button">
            <ion-spinner class="spinner" v-if="BackwardBusy" name="crescent"></ion-spinner>
            <ion-icon v-else :icon="chevronBackOutline"></ion-icon>
        </ion-button>
        <ion-button :disabled="PagePickerBusy" class="ion-padding-start ion-padding-end" fill="clear"
            @click="setOpen(true)">
            Page&nbsp;
            <span v-if="PagePickerBusy">
                <ion-spinner class="spinner" name="crescent"></ion-spinner>
            </span>
            <span v-else>
                {{ PageNumber }}
            </span>

            <ion-icon class="ion-padding-start" :icon="chevronDown"></ion-icon>
        </ion-button>
        <ion-button :disabled="!CanGoForward || ForwardBusy" @click="GoForward" color="primary" slot="end"
            class="forward-button">
            <ion-spinner class="spinner" v-if="ForwardBusy" name="crescent"></ion-spinner>
            <ion-icon v-else :icon="chevronForwardOutline"></ion-icon>
        </ion-button>
        <ion-action-sheet :is-open="isOpen" header="Jump to page" :buttons="actionSheetButtons"
            @didDismiss="setOpen(false)"></ion-action-sheet>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { chevronBackOutline, chevronForwardOutline, chevronDown } from "ionicons/icons";
import {
    IonButton,
    IonIcon,
    IonSpinner,
    IonActionSheet
} from "@ionic/vue";

export default defineComponent({
    components: {
        IonButton,
        IonIcon,
        IonSpinner,
        IonActionSheet
    },
    props: {
        CanGoBackward: {
            type: Boolean,
            required: true
        },
        CanGoForward: {
            type: Boolean,
            required: true
        },
        PageNumber: {
            type: Number,
            required: true
        },
        PageCount: {
            type: Number,
            required: true
        },
        BackwardBusy: {
            type: Boolean,
            required: true
        },
        ForwardBusy: {
            type: Boolean,
            required: true
        },
        PagePickerBusy: {
            type: Boolean,
            required: true
        }
    },
    setup(props, { emit }) {
        const isOpen = ref(false);

        const setOpen = (value: boolean) => {
            CalcActionSheetButtons();
            isOpen.value = value;
        }

        const actionSheetButtons: { text: string, handler: () => void }[] = [];

        const CalcActionSheetButtons = () => {
            actionSheetButtons.length = 0;  // Clear the array

            for (let i = 1; i <= props.PageCount; i++) {
                actionSheetButtons.push({
                    text: `Page ${i}`,
                    handler: () => {
                        emit('Go-To-Page', i);
                    }
                })
            }
        }
        const GoBackward = () => {
            emit('Go-Backward');
        }
        const GoForward = () => {
            emit('Go-Forward');
        }
        return {
            chevronBackOutline,
            chevronForwardOutline,
            actionSheetButtons,
            GoBackward,
            GoForward,
            chevronDown,
            isOpen,
            setOpen
        }
    }
});
</script>

<style scoped>
.pagination-button-group {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pagination-button-group>ion-button {
    margin: 0px;
}

.spinner {
    width: 1em;
    height: 1em;
}
</style>