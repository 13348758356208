<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-menu-button color="tertiary"></ion-menu-button>
        </ion-buttons>
        <ion-buttons slot="end" router-link="/alert">
          <ion-button slot="end" size="default" color="tertiary" fill="solid" shape="round">
            <ion-icon :icon="add" />
            &nbsp; New Alert
          </ion-button>
        </ion-buttons>
        <ion-title>Dashboard</ion-title>
        <ion-progress-bar color="tertiary" v-show="isLoading" type="indeterminate"></ion-progress-bar>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <!--Active Products-->
      <ion-list lines="none">
        <ion-item class="header-container">
          <ion-segment color="primary" class="dashboard-toggle" v-model="dashboardMode">
            <ion-segment-button value="active" @click="GetAlerts(1)">
              <ion-label>Active ({{ alertCounts.active }}/{{ alertCounts.max }})</ion-label>
            </ion-segment-button>
            <ion-segment-button value="inactive" @click="GetAlerts(1)">
              <ion-label>Disabled ({{ alertCounts.inactive }})</ion-label>
            </ion-segment-button>
          </ion-segment>
          <ion-button v-show="!isLoading" v-if="dashboard.alerts.length > 0" @click="isEditMode = !isEditMode"
            slot="end" size="default" shape="round" fill="clear">
            <ion-icon slot="icon-only" size="small" :icon="pencil" />
          </ion-button>
        </ion-item>
      </ion-list>
      <!-- If no products exist-->
      <ion-grid v-if="hasAlerts === false && isLoading == false">
        <ion-item lines="none">
          <ion-label v-if="dashboardMode == 'active'" color="medium">
            You don't have any active alerts right now.
          </ion-label>
          <ion-label v-else color="medium">
            You don't have any disabled alerts right now.
          </ion-label>
        </ion-item>
        <ion-item lines="none">
          <ion-button router-link="/alert" expand="block" size="medium">Create new alert</ion-button>
        </ion-item>
      </ion-grid>
      <!-- EndIf no products exist-->
      <!-- Product Table Header-->
      <div>
        <ion-searchbar
          v-show="alertCounts.active > 50 && dashboardMode == 'active' || alertCounts.inactive > 50 && dashboardMode == 'inactive'"
          v-model="searchText" :debounce="500" placeholder="Search Alerts"></ion-searchbar>
        <ion-list v-if="dashboard.alerts.length > 0" style="margin-left: 0px; margin-right: 0px;">
          <ProductItem v-for="product in dashboard.alerts" :key="product.AlertID" :id="product.AlertID"
            :product="product" ref="slidingItems" v-on:ProductEditedEvent="HandleEditProduct()"
            v-on:ProductDeletedEvent="HandleProductDeleted(product.AlertID)" />
        </ion-list>
        <ion-list style="margin-bottom: 80px;">
          <!--Pagination Control-->
          <PaginationButtons v-if="dashboard.totalPages > 1" :CanGoBackward="CanGoBackward()"
            :PagePickerBusy="PagePickerBusy" :CanGoForward="CanGoForward()" :PageNumber="dashboard.pageNumber"
            :PageCount="dashboard.totalPages" :BackwardBusy="BackwardBusy" :ForwardBusy="ForwardBusy"
            @Go-Backward="HandleGoBackward()" @Go-Forward="HandleGoForward()"
            @Go-To-Page="PagePickerBusy = true; GetAlerts($event, searchText)" />
        </ion-list>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from "vue";
import {
  onIonViewDidEnter,
  IonLabel,
  IonItem,
  IonButtons,
  IonButton,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonList,
  IonToolbar,
  IonIcon,
  IonGrid,
  IonProgressBar,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
} from "@ionic/vue";
import PaginationButtons from "../components/PaginationButtons.vue";
import { add, chevronBackOutline, chevronForwardOutline, pencil } from "ionicons/icons";
import ProductItem from "../components/ProductItem.vue";
import { DashboardProduct, PaginatedDashboardProductResult } from "../models/Product";
import { SniperAPI } from "../utils/AxiosInstance.js";
import { toast } from "../utils/SimpleToast.js";
import { useRouter } from "vue-router";
import SniperResponse from "@/models/SniperResponse";

export default defineComponent({
  components: {
    IonLabel,
    IonItem,
    IonButtons,
    IonButton,
    IonContent,
    IonIcon,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonList,
    IonToolbar,
    IonGrid,
    IonProgressBar,
    ProductItem,
    IonSegment,
    IonSegmentButton,
    PaginationButtons,
    IonSearchbar
  },
  setup() {
    // This is where data is defined using the Composition API. Return it to expose it to the rest of the DOM.
    const isLoading = ref(true);
    const BackwardBusy = ref(false);
    const ForwardBusy = ref(false);
    const PagePickerBusy = ref(false);
    const dashboardMode = ref("active")
    const dashboard = ref<PaginatedDashboardProductResult>({ //default values onload
      alerts: [],
      pageNumber: 0,
      pageSize: 0,
      totalPages: 1,
    });
    const slidingItems = ref<typeof ProductItem[]>([]);
    const isEditMode = ref(false);

    const alertCounts = ref({
      active: 0,
      inactive: 0,
      max: 0,
      userID: 0,
    });
    const searchText = ref("");

    const HandleEditProduct = () => {
      GetAlerts(dashboard.value.pageNumber, searchText.value);
      GetAlertCounts();
    };

    const HandleProductDeleted = (id: any) => {
      dashboard.value.alerts = dashboard.value.alerts.filter((product) => product.AlertID !== id);
    }

    const GetAlerts = async (page: number, searchText = ""): Promise<boolean> => {
      isLoading.value = true;
      try {
        const response = await SniperAPI.get<SniperResponse>("dashboard/GetAlerts", {
          params: {
            active: dashboardMode.value === "active" ? true : false,
            pageNumber: page,
            pageSize: 200,
            searchText: searchText,
          }
        });

        let sniperResponse = response.data;

        if (!sniperResponse.success) {
          toast("There was an error loading your alerts.");
          // TODO: Give retry button.
          return false;
        }

        let products: DashboardProduct[] = sniperResponse.results.alerts.map((alert: any) => new DashboardProduct(alert));

        dashboard.value.alerts = products;
        dashboard.value.pageNumber = sniperResponse.results.pageNumber;
        dashboard.value.pageSize = sniperResponse.results.pageSize;
        dashboard.value.totalPages = sniperResponse.results.totalPages;
        GetAlertCounts(); // TODO: refactor into the same call as GetAlerts

        return true;

      } catch (error: any) {
        console.error(error);
        toast("An unknown error occured.");
        return false;
      }
      finally {
        isLoading.value = false;
        ForwardBusy.value = false;
        BackwardBusy.value = false;
        PagePickerBusy.value = false;
      }
    };

    // Watch searchText and call GetAlerts when it changes
    watch(searchText, (newSearchText, oldSearchText) => {

      // If user is searching for the first time or clearing the, reset the page number to 1.
      if (oldSearchText === "" || newSearchText === "") {
        GetAlerts(1, newSearchText);
      }
      else {
        GetAlerts(dashboard.value.pageNumber, newSearchText);
      }
    });

    const GetAlertCounts = () => {
      SniperAPI.get("dashboard/GetAlertCounts").then(function (response: any) {
        let data = response.data;
        if (data.success) {
          alertCounts.value = data.alertCounts;
        }
      });
    };

    const hasAlerts = computed(() => {

      // If the user doesn't have alerts, show a message and a button to create a new alert.
      // This still works if the dashboard search result returns no results.
      if (dashboardMode.value === "active" && alertCounts.value.active == 0) {
        return false;
      }

      if (dashboardMode.value === "inactive" && alertCounts.value.inactive == 0) {
        return false;
      }
      return true;

    });

    onIonViewDidEnter(() => {
      GetAlerts(1);
    })

    const HandleGoBackward = () => {
      BackwardBusy.value = true;
      GetAlerts(dashboard.value.pageNumber - 1, searchText.value);
    };
    const HandleGoForward = () => {
      ForwardBusy.value = true;
      GetAlerts(dashboard.value.pageNumber + 1, searchText.value);

    };
    const CanGoBackward = () => {
      return dashboard.value.pageNumber > 1;
    };
    const CanGoForward = () => {
      return dashboard.value.pageNumber < dashboard.value.totalPages;
    };

    watch(isEditMode, async (newValue) => {
      if (newValue) {
        slidingItems.value.forEach((item) => {
          item.Open()
        });
      } else {
        slidingItems.value.forEach((item) => {
          item.Close()
        });
      }
    });


    return {
      add,
      pencil,
      chevronBackOutline,
      chevronForwardOutline,
      alertCounts,
      GetAlerts,
      HandleEditProduct,
      HandleProductDeleted,
      GetAlertCounts,
      isLoading,
      dashboard,
      dashboardMode,
      CanGoForward,
      CanGoBackward,
      BackwardBusy,
      ForwardBusy,
      PagePickerBusy,
      HandleGoForward,
      HandleGoBackward,
      slidingItems,
      isEditMode,
      searchText,
      hasAlerts,
    };
  },
  props: {},
  // data() {
  //   return {
  //     products: Product[],
  //   };
  // },
});
</script>

<style scoped>
.scroller {
  height: 100%;
}

/* List Transitions */
.list-enter-from {
  opacity: 0;
  transform: translateY(295px);
}

.list-enter-active {
  transition: all 0.4s ease;
}

.list-leave-to {
  opacity: 0;
  transform: translateY(295px);
}

.list-leave-active {
  transition: all 0.4s ease;
  position: absolute;
  /* for move transition after item leaves */
}

.list-move {
  transition: all 0.3s ease;
}

.dashboard-toggle {
  justify-content: normal;
  display: flex;
}

.header {
  font-weight: 600;
}

.header>span {
  color: var(--ion-color-primary);
}

#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

#container a {
  text-decoration: none;
}

#page-margin {
  margin-bottom: 30px;
}
</style>
