// A random junk drawer of helper functions

export function debounce (fn, delay) {
    var timeoutID = null
    return function () {
      clearTimeout(timeoutID)
      var args = arguments
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      var me = this
      timeoutID = setTimeout(function () {
        fn.apply(me, args)
      }, delay)
    }
  }

export function toUSD (amount) {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount)
}


export function GetStripeInstance() {
  // Potentially no longer needed after checkout API upgrade
  // Installed globally in /public/index.html
  // eslint-disable-next-line no-undef
  return Stripe(window._STRIPE_PUBLISHABLE_KEY);
}

// Moved here from the LoginManager due to a circular dependency with the Router. 
// Maybe a more elegant solution can come later.
export function IsAuthenticated(){
  if (GetCookie("AuthToken") == null)
  {
   return false
  }
  return true;
}

export function GetCookie(name) {
  // Stolen from https://stackoverflow.com/questions/5968196/how-do-i-check-if-a-cookie-exists
  var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    }
    else
    {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
        end = dc.length;
        }
    }
    return decodeURI(dc.substring(begin + prefix.length, end));
}
