import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_item, {
      class: "header",
      lines: "none"
    }, {
      default: _withCtx(() => [
        _createTextVNode("Alert type")
      ]),
      _: 1
    }),
    _createVNode(_component_ion_radio_group, {
      modelValue: _ctx.alertType,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.alertType) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              class: _normalizeClass(["ion-margin-end", { 'icon-price-decrease': _ctx.isPriceDrop }]),
              icon: _ctx.arrowDownOutline
            }, null, 8, ["icon", "class"]),
            _createVNode(_component_ion_radio, {
              justify: "space-between",
              value: _ctx.AlertMode.PriceDrop
            }, {
              default: _withCtx(() => [
                _createTextVNode("Price drop")
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              class: _normalizeClass(["ion-margin-end", { 'icon-price-increase': _ctx.isPriceIncrease }]),
              icon: _ctx.arrowUpOutline
            }, null, 8, ["icon", "class"]),
            _createVNode(_component_ion_radio, {
              justify: "space-between",
              value: _ctx.AlertMode.PriceIncrease
            }, {
              default: _withCtx(() => [
                _createTextVNode("Price increase")
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        }),
        _createVNode(_component_ion_item, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, {
              class: _normalizeClass(["ion-margin-end", { 'icon-any-change-in-price': _ctx.isAnyChangeInPrice }]),
              icon: _ctx.statsChartOutline
            }, null, 8, ["icon", "class"]),
            _createVNode(_component_ion_radio, {
              justify: "space-between",
              value: _ctx.AlertMode.AnyChangeInPrice
            }, {
              default: _withCtx(() => [
                _createTextVNode("Any change in price")
              ]),
              _: 1
            }, 8, ["value"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}