<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-img class="logo ion-margin-start" src="https://cdn.tcgsniper.com/TCGSniperLogo.svg"></ion-img>
        <ion-button slot="end" fill="clear" @click="loginManager.Logout()">LOG OUT</ion-button>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-grid class="ion-h-100 ion-padding-0">
        <ion-row class="column-wrapper ion-h-100">
          <ion-col size="12" size-xl="6">
            <SupportedCardsSwiper></SupportedCardsSwiper>
          </ion-col>
          <ion-col class="ion-padding-bottom-0 ion-h-100 card-container" size="12" size-xl="6">
            <div class="card">
              <div class="card-header ion-padding-top">
                <h1>Select a plan</h1>
                <p>
                  Pick your package. Cancel anytime. Pretty straight-forward.
                </p>
              </div>

              <ion-row>
                <ion-col>
                  <ion-item class="ion-margin-bottom" lines="none">
                    <UpgradeToPlusCTA />
                  </ion-item>
                  <ion-item lines="none">
                    <FreePlanCTA />
                  </ion-item>
                  <div class="card-footer">
                    <p>
                      Secured with
                      <a target="_blank" href="https://stripe.com/customers">Stripe</a>
                      and protected by our generous
                      <a target="_blank" href="https://tcgsniper.com/terms#refund">refund policy</a>.
                    </p>
                  </div>
                </ion-col>
              </ion-row>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButton,
  IonContent,
  IonHeader,
  IonGrid,
  IonRow,
  IonPage,
  IonItem,
  IonCol,
  IonToolbar,
  IonImg,
} from "@ionic/vue";
import { defineComponent } from "@vue/runtime-core";
import FreePlanCTA from "@/components/FreePlanCTA.vue";
import UpgradeToPlusCTA from "@/components/UpgradeToPlusCTA.vue";
import { LoginFactory } from "@/utils/LoginManager";
import SupportedCardsSwiper from "@/components/SupportedCardsSwiper.vue";

export default defineComponent({
  components: {
    IonButton,
    IonContent,
    IonHeader,
    IonGrid,
    IonPage,
    IonItem,
    IonRow,
    IonCol,
    IonToolbar,
    IonImg,
    FreePlanCTA,
    UpgradeToPlusCTA,
    SupportedCardsSwiper,
  },
  setup() {
    const loginManager = LoginFactory.GetLoginManager();
    return {
      UpgradeToPlusCTA,
      loginManager,
    };
  },
});
</script>

<style scoped>
.column-wrapper {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../components/images/TCGSniperAlertCollage.png");
  background-size: cover;
}

.card {
  height: 95%;
  margin-top: 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background: var(--ion-background-color);
  box-shadow: 10px 5px 5px black;
}

.logo {
  max-width: 150px;
}

@media only screen and (max-width: 992px) {
  .card {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: none;
  }

  .card-container {
    padding: 0;
  }
}

.card-header {
  text-align: center;
  margin-bottom: 10px;
}

.card-footer {
  text-align: center;
  padding-bottom: 0;
  margin-bottom: 0;
}

@media only screen and (max-width: 992px) {
  .card-footer {
    margin-bottom: 50px;
  }
}

.card-header h1 {
  font-size: 40px;
  font-weight: 600;
}

.card-header p {
  font-size: 20px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.card-footer p {
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
  padding: 10px;
}

#container {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

#container strong {
  font-size: 20px;
  line-height: 26px;
}

#container a {
  text-decoration: none;
}
</style>
