import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withKeys as _withKeys, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-db51fac2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "container" }
const _hoisted_2 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_PageNote = _resolveComponent("PageNote")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_text = _resolveComponent("ion-text")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_img, {
              class: "landing-page-logo",
              src: "https://cdn.tcgsniper.com/TCGSniperLogo.svg"
            }),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_ion_grid, null, {
                default: _withCtx(() => [
                  (_ctx.disableForm)
                    ? (_openBlock(), _createBlock(_component_ion_row, {
                        key: 0,
                        class: "ion-justify-content-center"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            offset: "0",
                            "offset-md": "0",
                            size: "12",
                            "size-md": "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_PageNote, {
                                icon: _ctx.mailOutline,
                                text: "Success! Check your inbox for for further instructions."
                              }, null, 8, ["icon"])
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.disableForm === false && _ctx.hasValidToken == false)
                    ? (_openBlock(), _createBlock(_component_ion_row, { key: 1 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            offset: "0",
                            "offset-md": "3",
                            size: "12",
                            "size-md": "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_input, {
                                    label: "Email",
                                    "label-placement": "floating",
                                    onKeyup: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.Submit()), ["enter"])),
                                    modelValue: _ctx.email,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event)),
                                    type: "email"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              (_ctx.isBusy)
                                ? (_openBlock(), _createBlock(_component_ion_button, {
                                    key: 0,
                                    size: "large",
                                    class: "loginbtn",
                                    expand: "block",
                                    disabled: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_spinner, { name: "circular" }),
                                      _createTextVNode("     " + _toDisplayString(_ctx.GetTitle()), 1)
                                    ]),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_ion_button, {
                                    key: 1,
                                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Submit())),
                                    size: "large",
                                    class: "loginbtn",
                                    expand: "block"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.GetTitle()), 1)
                                    ]),
                                    _: 1
                                  })),
                              _createVNode(_component_ion_button, {
                                class: "loginbtn",
                                fill: "clear",
                                "router-link": "/login"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Login")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_button, {
                                class: "loginbtn",
                                fill: "clear",
                                "router-link": "/join"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Sign Up")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (_ctx.hasValidToken)
                    ? (_openBlock(), _createBlock(_component_ion_row, { key: 2 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_col, {
                            offset: "0",
                            "offset-md": "3",
                            size: "12",
                            "size-md": "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, { class: "ion-margin-bottom" }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_input, {
                                    label: "New Password",
                                    "label-placement": "floating",
                                    onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => (_ctx.ResetPass()), ["enter"])),
                                    modelValue: _ctx.pass,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pass) = $event)),
                                    type: "password"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_input, {
                                    label: "Confirm Password",
                                    "label-placement": "floating",
                                    onKeyup: _cache[5] || (_cache[5] = _withKeys(($event: any) => (_ctx.ResetPass()), ["enter"])),
                                    modelValue: _ctx.passConfirm,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.passConfirm) = $event)),
                                    type: "password"
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              (_ctx.newPassErrors.length > 0)
                                ? (_openBlock(), _createBlock(_component_ion_item, {
                                    key: 0,
                                    lines: "none"
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.newPassErrors, (err, index) => {
                                        return (_openBlock(), _createElementBlock("div", { key: index }, [
                                          _createVNode(_component_ion_text, { color: "danger" }, {
                                            default: _withCtx(() => [
                                              _createTextVNode(_toDisplayString(err), 1)
                                            ]),
                                            _: 2
                                          }, 1024)
                                        ]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              (_ctx.isBusy)
                                ? (_openBlock(), _createBlock(_component_ion_button, {
                                    key: 1,
                                    size: "large",
                                    class: "loginbtn",
                                    expand: "block",
                                    disabled: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_ion_spinner, { name: "circular" }),
                                      _createTextVNode("     " + _toDisplayString(_ctx.GetTitle()), 1)
                                    ]),
                                    _: 1
                                  }))
                                : (_openBlock(), _createBlock(_component_ion_button, {
                                    key: 2,
                                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.ResetPass())),
                                    size: "large",
                                    class: "loginbtn",
                                    expand: "block"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.GetTitle()), 1)
                                    ]),
                                    _: 1
                                  })),
                              _createVNode(_component_ion_button, {
                                class: "loginbtn",
                                fill: "clear",
                                "router-link": "/login"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Login")
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_button, {
                                class: "loginbtn",
                                fill: "clear",
                                "router-link": "/join"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("Sign Up")
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}