// Quick implimentation of Ionic's toast component. If you don't need icons, buttons, callbacks, etc use this.
import {toastController} from "@ionic/vue";
import {checkmarkCircleOutline} from "ionicons/icons";

export const toast = async (message = "Ipsum Lorem Toast", icon = "", duration = 4000, color = "") => {
  const toast = await toastController
  .create({
    message: message,
    duration: duration,
    icon: icon == "" ? undefined : icon,
    color: color == "" ? undefined : color
  })
return toast.present();
  }

  export const successToast = async (message = "Ipsum Lorem Toast", duration = 4000, ) => {
    const toast = await toastController
    .create({
      message: message,
      duration: duration,
      icon: checkmarkCircleOutline,
      color: "success"
    })
  return toast.present();
}