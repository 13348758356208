<template>
  <ion-header>
    <ion-toolbar>
      <ion-title>Request Cancellation</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="modalController.dismiss()" color="medium"
          >Go back</ion-button
        >
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <div class="label-body">
      <ion-label class="label-text">
        We are sorry our Plus subscription was not right for you. Clicking the
        button below will generate a request for our team to cancel your
        subscription ASAP and issue a refund (as applicable).
      </ion-label>
    </div>
    <ion-text>
      <h2>We are here for you.</h2>
      <a color="light" href="mailto:help@tcgsniper.com">help@tcgsniper.com</a>
    </ion-text>
  </ion-content>
  <footer class="form-footer">
    <ion-button @click="RequestCancellation()" color="danger" expand="block">
      <span>&nbsp;</span>
      Cancel subscription
    </ion-button>
  </footer>
</template>
<style scoped>
.label-body {
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  margin-bottom: 1rem;
  background-color: var(--ion-color-light-tint);
  border-color: var(--ion-color-light-tint);
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.label-text {
  line-height: 1.5;
}
.form-footer {
  bottom: 2rem;
  width: 100%;
  min-height: 56px;
  margin-bottom: 3rem;
  flex: right;
}
</style>
<script lang="ts">
import {
  IonItem,
  IonHeader,
  IonContent,
  IonButtons,
  IonButton,
  IonToolbar,
  modalController,
} from "@ionic/vue";
import { defineComponent, reactive, ref } from "vue";
import { searchOutline } from "ionicons/icons";
import DashboardPage from "../DashboardPage.vue";
import { SniperAPI } from "@/utils/AxiosInstance";
import SniperResponse from "@/models/SniperResponse";
import { toast } from "@/utils/SimpleToast";

export default defineComponent({
  name: "RequestCancellationModal",
  components: {
    IonHeader,
    IonContent,
    IonButtons,
    IonButton,
    IonToolbar,
  },
  setup(props) {
    const RequestCancellation = () => {
      SniperAPI.post<SniperResponse>("settings/RequestSubscriptionCancellation")
        .then(function (response: any) {
          if (response.data.success) {
            toast("Your request was sent successfully.");
            modalController.dismiss();
          } else {
            toast(
              "An unknown error occured. Please email us immediately help@tcgsniper.com"
            );
          }
        })
        .catch(function (error) {
          toast(
            "An unknown error occured. Please email us immediately help@tcgsniper.com"
          );
          console.error(error.toString());
        });
    };
    return {
      modalController,
      RequestCancellation,
    };
  },
  props: {},
});
</script>
