export class UserModel {
    Id: number; 
    Email: string;
    Name: string;
    PhoneNumber: string;
    PhoneNumberNational: string;
    DiscordWebhookURL: string;
    UserSettings: UserSettingsModel;
    /**
     *
     */
    constructor() {
        this.Id = 0;
        this.Email = "";
        this.Name = "";
        this.PhoneNumber = "";
        this.PhoneNumberNational = "";
        this.DiscordWebhookURL = "";
        this.UserSettings = new UserSettingsModel();
    }
}

// Minor refactoring here for now. This really should be overhaulded
export class UserSettingsModel {
    Notifications: NotificationChannelSettings;
    /**
     *
     */
    constructor() {
        this.Notifications = new NotificationChannelSettings();
   
    }
}

class NotificationChannelSettings {
    EmailNotifications: boolean;
    SMSNotifications: boolean;
    DiscordNotifications: boolean;

    /**
     *
     */
    constructor() {
        this.EmailNotifications = true;
        this.SMSNotifications = false;
        this.DiscordNotifications = false;
        }
}