<template>
  <ion-item class="header" lines="none">Alert type</ion-item>
  <ion-radio-group v-model="alertType">
    <ion-item>
      <ion-icon class="ion-margin-end" :icon="arrowDownOutline"
        v-bind:class="{ 'icon-price-decrease': isPriceDrop }"></ion-icon>
      <ion-radio justify="space-between" :value="AlertMode.PriceDrop">Price drop</ion-radio>
    </ion-item>
    <ion-item>
      <ion-icon class="ion-margin-end" :icon="arrowUpOutline"
        v-bind:class="{ 'icon-price-increase': isPriceIncrease }"></ion-icon>
      <ion-radio justify="space-between" :value="AlertMode.PriceIncrease">Price increase</ion-radio>
    </ion-item>
    <ion-item>
      <ion-icon class="ion-margin-end" :icon="statsChartOutline"
        v-bind:class="{ 'icon-any-change-in-price': isAnyChangeInPrice }"></ion-icon>
      <ion-radio justify="space-between" :value="AlertMode.AnyChangeInPrice">Any change in price</ion-radio>
    </ion-item>
  </ion-radio-group>
</template>
<style>
.icon-price-decrease {
  color: var(--ion-color-success);
}

.icon-price-increase {
  color: var(--ion-color-danger);
}

.icon-any-change-in-price {
  color: var(--ion-color-warning);
}
</style>
<script lang="ts">
import { IonItem, IonRadioGroup, IonRadio, IonIcon } from "@ionic/vue";
import { defineComponent, ref, PropType, computed, watch } from "vue";
import {
  arrowUpOutline,
  arrowDownOutline,
  statsChartOutline,
} from "ionicons/icons";
import { AlertMode } from "@/utils/Enums";

export default defineComponent({
  components: {
    IonItem,
    IonRadioGroup,
    IonRadio,
    IonIcon,
  },
  emits: ["AlertTypeChangedEvent"],
  setup(props, { emit }) {
    // Current alertType/PriceRestriction
    const alertType = ref<AlertMode>(props.InitialAlertType);

    // Used for styling purposes
    const isPriceDrop = computed(() => {
      if (alertType.value == AlertMode.PriceDrop) return true;
      else return false;
    });
    const isPriceIncrease = computed(() => {
      if (alertType.value == AlertMode.PriceIncrease) return true;
      else return false;
    });
    const isAnyChangeInPrice = computed(() => {
      if (alertType.value == AlertMode.AnyChangeInPrice) return true;
      else return false;
    });

    watch(alertType, async () => {
      emit("AlertTypeChangedEvent", alertType.value);
    });

    return {
      arrowUpOutline,
      arrowDownOutline,
      statsChartOutline,
      alertType,
      AlertMode,
      isPriceDrop,
      isPriceIncrease,
      isAnyChangeInPrice,
    };
  },
  props: {
    InitialAlertType: {
      required: true,
      type: Number as PropType<AlertMode>,
    },
  },
});
</script>
