<template>
  <ion-item id="present-logout" button lines="none" class="hydrated">
    <ion-icon slot="start" :ios="arrowBack" :md="arrowBack"></ion-icon>
    <ion-label>Log Out</ion-label>
  </ion-item>




  <ion-alert trigger="present-logout" header="Confirm Log Out" :buttons="alertButtons"
    @didDismiss="logResult($event)"></ion-alert>
</template>
<script lang="ts">
import { IonItem, IonIcon, IonLabel, IonAlert } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { arrowBack } from "ionicons/icons";
import { LoginFactory } from "@/utils/LoginManager";

export default defineComponent({
  components: {
    IonItem,
    IonIcon,
    IonLabel,
    IonAlert
  },
  setup() {

    const alertButtons = [
      {
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          console.log('Alert canceled');
        },
      },
      {
        text: 'Log Out',
        role: 'confirm',
        handler: () => {
          loginManager.Logout();
        },
      },
    ];

    const logResult = (ev: CustomEvent) => {
      console.log(`Dismissed with role: ${ev.detail.role}`);
    };

    const loginManager = LoginFactory.GetLoginManager();

    const logout = () => {
      loginManager.Logout();
    };
    return { arrowBack, logout, alertButtons, logResult };
  },
});
</script>
<style scoped>
ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item ion-icon {
  color: var(--ion-color-medium);
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}
</style>
