import { SniperAPI } from "./AxiosInstance";
import { toast } from "../utils/SimpleToast.js";
import { AxiosResponse } from "axios";
import router from "../router/index";
import DashboardPage from "../views/DashboardPage.vue";
import { ExceptionCode } from "@capacitor/core";
import { inject } from "vue";
import FullStory from "@fullstory/browser";
import { Crisp } from "crisp-sdk-web";
import { useUserStore } from "@/store";

export interface ILoginManager {
  //isAuthenticated(): boolean
  Login(email: string, pass: string): Promise<boolean>;
  Logout(): void;
  SignUp(
    name: string,
    email: string,
    password: string,
    phone: string,
    acceptedTerms: boolean
  ): Promise<SignUpResponse>;
  RequestResetToken(email: string): Promise<boolean>;
  ResetPassword(pass: string, token: string): Promise<boolean>;
  PasswordMeetsRequirements(pass: string): [boolean, string[]];
}

export class LoginFactory {
  static GetLoginManager(): ILoginManager {
    return new LoginManager();
  }
}

export class SignUpResponse {
  success = false;
  errors: string[] = [];
}

class LoginManager implements ILoginManager {
  //isAuthenticated(): boolean {
  //   if (GetCookie("AuthToken") == null)
  //   {
  //    return false
  //   }
  //   return true;
  //}

  async Login(email: string, password: string): Promise<boolean> {
    try {
      const response: AxiosResponse<any, any> = await SniperAPI.post(
        "/login",
        null,
        { params: { email, password } }
      );

      const r = response.data;
      const userStore = useUserStore();

      if (r.success) {
        const fs = window._FULLSTORY_INSTANCE;
        fs?.identify(r.UserID, {
          email: email,
        });
        fs?.event("Login", {
          uid_str: r.UserID,
        });
        //Crisp.session.setData({
        //    user_id : r.UserID
        //  });

        toast("Welcome back 🤘");
        router.push("dashboard");
        userStore.setUserIsLoggedIn(true);
        return true;
      } else {
        toast(r.error);
        return false;
      }
    } catch (error) {
      console.warn(error);
      return false;
    }
  }
  Logout(): void {
    const userStore = useUserStore();

    SniperAPI.get("/logout").then(function () {
      router.push("login");
      userStore.setUserIsLoggedIn(false);
    });
  }

  async SignUp(
    name: string,
    email: string,
    password: string,
    number: string,
    acceptedTerms: boolean
  ): Promise<SignUpResponse> {
    const response: AxiosResponse<any, any> = await SniperAPI.post(
      "/signup",
      null,
      { params: { name, email, password, number, acceptedTerms } }
    );
    const r = response.data;
    const status = new SignUpResponse();

    status.success = r.success;
    status.errors.push(r.error);

    if (r.success) {
      const fs = window._FULLSTORY_INSTANCE;
      fs?.identify(r.UserID, {
        email: email,
      });
      fs?.event("Login", {
        uid_str: r.UserID,
      });
      //Crisp.session.setData({
      //    user_id : r.UserID
      //  });
    }

    return status;
  }

  async RequestResetToken(email: string) {
    const response: AxiosResponse<any, any> = await SniperAPI.post(
      "/request-token",
      null,
      { params: { email } }
    );
    const r = response.data;
    toast(r.data);
    return r.success;
  }

  async ResetPassword(pass: string, token: string) {
    const response: AxiosResponse<any, any> = await SniperAPI.post(
      "/reset-password",
      null,
      { params: { password: pass, token } }
    );
    const r = response.data;

    if (r.success) {
      toast("Welcome back 🤘");
      return true;
    } else {
      toast(response.data.error);
      return false;
    }
  }

  PasswordMeetsRequirements(pass: string): [boolean, string[]] {
    let success = true;
    // eslint-disable-next-line prefer-const
    let errs: string[] = [];

    if (pass.length < 5) {
      success = false;
      errs.push("Password must be at least 6 characters.");
    }

    return [success, errs];
  }
}
