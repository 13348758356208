// userStore.ts
import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
//import { IsAuthenticated } from './utils/helpers';

export const useUserStore = defineStore("user", {
  state: () => ({
    isLoggedin: useStorage("isLoggedIn", false), // Initial value is false
    role: useStorage("role", ""),
  }),
  actions: {
    // Action to set the user's login status
    setUserIsLoggedIn(status: boolean) {
      this.isLoggedin = status;
    },

    setRole(role: string) {
      this.role = role;
    },
  },
});