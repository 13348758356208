<template>
  <ion-item lines="none">
    <ion-label class="page-note">
      <ion-icon class="page-note-icon" v-if="icon !== undefined" slot="start" :icon="icon"></ion-icon>
      {{ text }}
    </ion-label>
  </ion-item>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { IonItem, IonLabel, IonIcon } from "@ionic/vue";

export default defineComponent({
  components: {
    IonLabel,
    IonItem,
    IonIcon,
  },
  props: {
    text: String,
    icon: String,
  },
});
</script>
<style scoped>
.page-note {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  /*color: var(--ion-color-light-shade);*/
  background-color: var(--ion-color-light-shade);
  border-color: var(--ion-color-light-shade);
  white-space: inherit;
  overflow: inherit;
}

.page-note-icon {
  position: relative;
  top: 3px;
  padding-right: 15px;
}
</style>
