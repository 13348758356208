import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_SidebarLogout = _resolveComponent("SidebarLogout")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_split_pane = _resolveComponent("ion-split-pane")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_split_pane, {
        disabled: false,
        "content-id": "main-content"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_menu, {
            "content-id": "main-content",
            type: "overlay",
            class: _normalizeClass({ 'ion-hide': !_ctx.userStore.isLoggedin })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_content, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_list, { id: "inbox-list" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_img, {
                        "router-link": "/",
                        class: "logo clickable",
                        src: _ctx.logo
                      }, null, 8, ["src"]),
                      _createVNode(_component_ion_menu_toggle, {
                        "auto-hide": !_ctx.userStore.isLoggedin
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appPages, (p, i) => {
                            return (_openBlock(), _createBlock(_component_ion_item, {
                              key: i,
                              button: "",
                              "router-direction": "root",
                              "router-link": p.url,
                              lines: "none",
                              detail: false,
                              class: _normalizeClass(["hydrated", { selected: _ctx.selectedIndex === i }])
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, {
                                  slot: "start",
                                  ios: p.iosIcon,
                                  md: p.mdIcon
                                }, null, 8, ["ios", "md"]),
                                _createVNode(_component_ion_label, null, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(p.title), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["router-link", "class"]))
                          }), 128)),
                          _createVNode(_component_SidebarLogout)
                        ]),
                        _: 1
                      }, 8, ["auto-hide"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_list, { id: "labels-list" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_list_header, null, {
                        default: _withCtx(() => [
                          _createTextVNode("Need something?")
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.helpLabels, (label, index) => {
                        return (_openBlock(), _createBlock(_component_ion_item, {
                          button: "",
                          onClick: ($event: any) => (_ctx.selectedIndex = index),
                          href: label.url,
                          target: "_blank",
                          lines: "none",
                          key: index
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_icon, {
                              slot: "start",
                              ios: label.iosIcon,
                              md: label.mdIcon
                            }, null, 8, ["ios", "md"]),
                            _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(label.title), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onClick", "href"]))
                      }), 128)),
                      _createVNode(_component_ion_item, {
                        class: "this-week-in-mtg",
                        button: "",
                        href: "https://tcgsniper.com/help/tcgsniper-featured-on-the-this-week-in-mtg-podcast/",
                        target: "_blank",
                        lines: "none"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            class: "this-week-in-mtg-icon",
                            slot: "start",
                            ios: _ctx.mic,
                            md: _ctx.micSharp
                          }, null, 8, ["ios", "md"]),
                          _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                            default: _withCtx(() => [
                              _createTextVNode("As seen on: This Week in MTG")
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["class"]),
          _createVNode(_component_ion_router_outlet, {
            animated: true,
            id: "main-content"
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}