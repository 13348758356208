<template>
  <ion-chip color="snoozed" @click.stop>
    <ion-icon color="snoozed" :icon="moon"></ion-icon>
    <ion-label>Snoozed</ion-label>
    <ion-icon :icon="closeCircle" color="medium" @click="HandleClick()"></ion-icon>
  </ion-chip>
</template>
<script lang="ts">
import { IonChip, IonIcon, IonLabel } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { toast } from "../utils/SimpleToast.js";
import { DashboardProduct } from "@/models/Product";
import {
  moon,
  closeCircle,
  time
} from "ionicons/icons";

export default defineComponent({
  components: {
    IonChip,
    IonIcon,
    IonLabel,
  },
  setup(props, { emit }) {
    const HandleClick = () => {
      emit("SnoozeDismissedEvent")
    }
    return { HandleClick, moon, closeCircle, time, emit };
  },
  props: {
    product: DashboardProduct,
  },
});
</script>
<style scoped>
ion-label {
  white-space: nowrap;
}
</style>
