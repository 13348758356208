<template>
  <ion-item
    class="isoloated-item"
    href="javascript:void(0);"
    @click="LaunchModal()"
    detail
    :lines="linesValue"
  >
    <ion-label>Conditions</ion-label>
    <ion-text color="medium">{{ hint }}</ion-text>
  </ion-item>
</template>
<script lang="ts">
import { IonLabel, IonItem, IonText, modalController } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import GenericMultiSelectModal from "@/views/modals/GenericMultiSelectModal.vue";

export default defineComponent({
  components: {
    IonLabel,
    IonItem,
    IonText,
  },
  emits: ["SkuSelectionEdited"],
  setup(props, { emit }) {
    const LaunchModal = async () => {
      const controller = await modalController.create({
        component: GenericMultiSelectModal,
        componentProps: {
          title: "Select SKUs to view pricing",
          
          confirmButton: "Done",
          items: props.skus,
          itemDisplayKey: "conditionName",
        },
        cssClass: "",
      });
      await controller.present();
      controller.onDidDismiss().then(() => {
        emit("SkuSelectionEdited", props.skus);
      });
    };
    return {
      LaunchModal,
    };
  },
  mounted() {
    return;
  },
  props: {
    linesValue: {
      required: false,
      type: String,
    },
    skus: {
      required: false,
      type: Array,
    },
    hint: {
      required: true,
      type: String,
    },
  },
});
</script>
<style>
ion-select.sku-selector {
  width: 100%;
  max-width: 100%;
}
</style>
