import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!

  return (_openBlock(), _createBlock(_component_ion_chip, {
    color: "snoozed",
    onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_icon, {
        color: "snoozed",
        icon: _ctx.moon
      }, null, 8, ["icon"]),
      _createVNode(_component_ion_label, null, {
        default: _withCtx(() => [
          _createTextVNode("Snoozed")
        ]),
        _: 1
      }),
      _createVNode(_component_ion_icon, {
        icon: _ctx.closeCircle,
        color: "medium",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.HandleClick()))
      }, null, 8, ["icon"])
    ]),
    _: 1
  }))
}