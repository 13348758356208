export default class TCGPlayerPriceModel {
    skuId: string;
    lowestListingPrice: number;
    lowPrice: number;

    constructor() {
        this.skuId = "",
        this.lowestListingPrice = -9,
        this.lowPrice = -9
    }
}