<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title>Settings</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <PageNote text="Edit your account information and preferences." />
      <ion-list>
        <ion-row>
          <ion-col size-md="6" size="12" class="">
            <div class="title-block">
              <h3 class="ion-margin-start">Account information</h3>
              <span class="ion-margin-start">
                Update your account information.
              </span>
            </div>
            <ion-item id="name">
              <ion-input label="Name" label-placement="stacked" v-model="user.Name" autocomplete="name"></ion-input>
            </ion-item>
            <ion-row>
              <ion-col>
                <ion-item id="email">
                  <ion-input label="Email" label-placement="stacked" v-model="user.Email" type="email"
                    autocomplete="email"></ion-input>
                </ion-item>
              </ion-col>
              <ion-col>
                <ion-item id="phone">
                  <ion-input label="Phone Number" label-placement="stacked" v-model="user.PhoneNumberNational"
                    type="tel" autocomplete="tel"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-col size-md="6" size="12" class="">
            <!--Base Notifications-->
            <div v-if="!isPlus" class="base-notifications">
              <div class="title-block">
                <h3 class="ion-margin-start">Notifications</h3>
                <span class="ion-margin-start">
                  Let us know how you would like to receive your notifications.
                </span>
              </div>
              <ion-item lines="none">
                <ion-checkbox label-placement="end" justify="start"
                  v-model="user.UserSettings.Notifications.EmailNotifications">Email</ion-checkbox>
              </ion-item>
              <ion-note class="ion-margin-start">Get an email once an alert has been completed.</ion-note>
              <ion-item lines="none">
                <ion-checkbox disabled label-placement="end" justify="start">SMS</ion-checkbox>
                <ion-badge slot="end" color="tertiary">
                  <ion-icon :icon="lockClosed"></ion-icon>
                  Plus
                </ion-badge>
              </ion-item>
              <ion-note class="ion-margin-start">Get a text message once an alert has been
                completed.</ion-note>
              <ion-item lines="none">
                <ion-checkbox disabled label-placement="end" justify="start">Discord Webhook</ion-checkbox>
                <ion-badge slot="end" color="tertiary">
                  <ion-icon :icon="lockClosed"></ion-icon>
                  Plus
                </ion-badge>
              </ion-item>
              <ion-note class="ion-margin-start">You will need to provide a Discord Webhook URL.</ion-note>
            </div>
            <!--Plus notifications-->
            <div v-else class="plus-notifications">
              <div class="title-block">
                <h3 class="ion-margin-start">Notifications</h3>
                <span class="ion-margin-start">
                  Let us know how you would like to receive your notifications.
                </span>
              </div>
              <ion-item lines="none">
                <ion-checkbox v-model="user.UserSettings.Notifications.EmailNotifications" label-placement="end"
                  justify="start">Email</ion-checkbox>
              </ion-item>
              <ion-note class="ion-margin-start">Get an email once an alert has been
                completed.</ion-note>
              <ion-item lines="none">
                <ion-checkbox v-model="user.UserSettings.Notifications.SMSNotifications" label-placement="end"
                  justify="start">SMS</ion-checkbox>
              </ion-item>
              <ion-note class="ion-margin-start">Get a text message once an alert has been
                completed.</ion-note>
              <ion-item lines="none">
                <ion-checkbox v-model="user.UserSettings.Notifications.DiscordNotifications" label-placement="end"
                  justify="start">Discord Webhook</ion-checkbox>
              </ion-item>
              <ion-note class="ion-margin-start">You will need to provide a Discord Webhook URL. </ion-note>
              <ion-item v-if="user.UserSettings.Notifications.DiscordNotifications">
                <ion-input id="discordWebhookURL" label="Webhook URL" label-placement="stacked"
                  v-model="user.DiscordWebhookURL" type="url"
                  placeholder="https://discordapp.com/api/webhooks/582237520599973896/26oMQiVLVnveYPyR1RQhHhzTmdqxkZIMmOsexGZ1d"></ion-input>
                <ion-button @click="TestDiscordWebhook()" size="medium" slot="end">Test</ion-button>
              </ion-item>
              <ion-note class="">
                <a href="#">How do I set up a Discord Webhook?</a>
              </ion-note>

            </div>
            <ion-item lines="none"> </ion-item>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-button class="ion-margin-start" style="min-width: 200px" @click="Save()">Save</ion-button>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size-md="6" size="12" class="">
            <div class="title-block">
              <h3 class="ion-margin-start">Subscription</h3>
              <span class="ion-margin-start">
                Update your subscription preferences.
              </span>
            </div>
            <div v-if="isPlus">
              <ion-item class="ion-margin-top" lines="none">
                <ion-input label="My Plan" label-placement="stacked" fill="solid" disabled value="Plus"></ion-input>
              </ion-item>
              <ion-row>
                <ion-col>
                  <ion-text style="display: block" class="ion-margin-top" color="danger"
                    v-if="hasCancellationRequests">Your cancellation request is being processed.</ion-text>
                  <ion-button color="medium" fill="outline" class="ion-margin-start ion-margin-top"
                    style="min-width: 200px" @click="OpenCancellationModal()" v-else>Cancel Subscription</ion-button>
                </ion-col>
              </ion-row>
            </div>
            <ion-item class="ion-margin-top" lines="none" v-else>
              <ion-input label="My Plan" label-placement="stacked" fill="solid" disabled value="Base"></ion-input>
            </ion-item>
            <ion-item lines="none">
              <ion-text v-if="canTrial" style="font-weight: bold" color="primary">Your account is eligible for 30 day
                free trial of
                Plus!</ion-text>
            </ion-item>
          </ion-col>
        </ion-row>
        <ion-item v-if="!isPlus" lines="none">
          <UpgradeToPlusCTA />
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>
<style scoped>
ion-note {
  padding-top: 0px;
  font-size: 14px;
}

.item-interactive-disabled.sc-ion-label-md-h:not(.item-multiple-inputs),
.item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-md-h {
  opacity: 1;
}

.title-block {
  border-left: 7px solid var(--ion-color-primary);
}

.title-block>span {
  display: block;
  margin-top: 4px;
  color: #555;
  font-weight: 300;
}

.title-block h3+span {
  font-size: 17px;
}

.title-block h1,
.title-block h2,
.title-block h3,
.title-block h4 {
  margin-bottom: 0;
}
</style>

<script lang="ts">
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonTitle,
  IonToolbar,
  IonRow,
  IonList,
  IonCol,
  IonInput,
  IonItem,
  IonCheckbox,
  IonButton,
  IonNote,
  IonText,
  IonIcon,
  IonBadge,
  modalController,
} from "@ionic/vue";
import { defineComponent, computed, reactive, ref } from "vue";
import {
  lockClosed,
  checkmark,
  mail,
  phonePortraitOutline,
  logoDiscord,
  reload,
} from "ionicons/icons";
import PageNote from "../components/PageNote.vue";
import { UserModel, UserSettingsModel } from "../models/UserModel";
import { SniperAPI } from "../utils/AxiosInstance.js";
import { successToast, toast } from "../utils/SimpleToast.js";
import RequestCancellationModal from "@/views/modals/RequestCancellationModal.vue";
import UpgradeToPlusCTA from "@/components/UpgradeToPlusCTA.vue";

//import { GlobalConfig } from "../GlobalConfig";
export default defineComponent({
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    PageNote,
    IonRow,
    IonList,
    IonCol,
    IonInput,
    IonItem,
    IonCheckbox,
    IonButton,
    IonNote,
    IonText,
    IonIcon,
    IonBadge,
    UpgradeToPlusCTA,
  },
  setup() {
    // Data
    const user = ref<UserModel>(new UserModel());
    const isPlus = ref<boolean>(false);
    const canTrial = ref<boolean>(false);
    const isBusy = ref<boolean>(false);
    const hasCancellationRequests = ref<boolean>(false);
    let isInputValid = true;
    const cssErrorClasses = ["invalid-item", "ion-invalid"];

    const Save = () => {
      isBusy.value = true;
      isInputValid = true;
      ValidateInput();

      if (!isInputValid) {
        isBusy.value = false;
        return;
      }

      // We display numbers in their nationality's format, which is calculated after being validated by twilio on the backend. To actually make edits to this field, edit the PhoneNumber prop instead.
      user.value.PhoneNumber = user.value.PhoneNumberNational;
      SniperAPI.post("settings/updateUserInfo", user.value).then(function (
        response: any
      ) {
        if (!response.data.success) {
          toast(response.data.error);
        } else {
          successToast("Account settings updated");
        }
      });
    };

    const TestDiscordWebhook = () => {
      SniperAPI.post("settings/testDiscordWebhook").then(function (
        response: any
      ) {
        let r = response.data;
        if (r.success) {
          toast("Your Discord test notification has been sent");
        } else {
          toast(r.error.toString());
        }
      });
    };

    function isEmptyOrSpaces(str: string) {
      return str === null || str.match(/^ *$/) !== null;
    }

    const ValidateInput = () => {
      // Remove Spaces
      debugger;
      try {
        user.value.Name = user.value.Name.trim();
        user.value.Email = user.value.Email.replace(/\s/g, "");
        user.value.DiscordWebhookURL = user.value.DiscordWebhookURL.replace(
          /\s/g,
          ""
        );

        if (user.value.Name.length == 0) {
          isInputValid = false;
          document.getElementById("name")?.classList.add(...cssErrorClasses);
        } else {
          document.getElementById("name")?.classList.remove(...cssErrorClasses);
        }

        if (user.value.Email.length == 0) {
          isInputValid = false;
          document.getElementById("email")?.classList.add(...cssErrorClasses);
        } else {
          document
            .getElementById("email")
            ?.classList.remove(...cssErrorClasses);
        }

        if (
          !user.value.UserSettings.Notifications.EmailNotifications &&
          !user.value.UserSettings.Notifications.SMSNotifications &&
          !user.value.UserSettings.Notifications.DiscordNotifications
        ) {
          isInputValid = false;
          toast(
            "Error: No notification preference is set. You must choose at least one option."
          );
        }

        if (
          !user.value.DiscordWebhookURL &&
          user.value.UserSettings.Notifications.DiscordNotifications
        ) {
          isInputValid = false;
          toast("Error: You must supply a Discord Webhook URL.");
          document
            .getElementById("discordWebhookURL")
            ?.classList.add("invalid-item");
        } else {
          document
            .getElementById("discordWebhookURL")
            ?.classList.remove("invalid-item");
        }
      } catch (error) {
        console.error(error);
      }
    };

    const RefreshUserProfile = () => {
      SniperAPI.get("settings/getUserInfo")
        .then(function (response: any) {
          let r = response.data.user;
          if (response.data.success) {
            user.value.Id = r.id;
            user.value.Email = r.email;
            user.value.Name = r.name;
            user.value.PhoneNumber = r.phoneNumber;
            user.value.DiscordWebhookURL = r.discordWebhook;
            user.value.PhoneNumberNational = r.phoneNumberNational;
            user.value.UserSettings.Notifications.EmailNotifications =
              r.userSettings.emailNotifications;
            user.value.UserSettings.Notifications.SMSNotifications =
              r.userSettings.textNotifications;
            user.value.UserSettings.Notifications.DiscordNotifications =
              r.userSettings.discordWebhook;
          }
        })
        .catch((error: any) => {
          console.error(error);
          toast("An unknown error occured.");
        });
    };

    const RefreshSubscriptionProfile = () => {
      SniperAPI.get("settings/getSubscriptionInfo")
        .then(function (response: any) {
          let r = response.data;
          if (r.success) {
            isPlus.value = r.plus;
            canTrial.value = r.trial;
          }
        })
        .catch((error: any) => {
          console.error(error);
          toast("An unknown error occured.");
        });
    };

    const GetCancellationRequests = () => {
      SniperAPI.get("settings/GetCancellationRequests").then(function (
        response: any
      ) {
        let r = response.data;
        if (r.success && r.count > 0) {
          hasCancellationRequests.value = true;
        }
      });
    };

    const OpenCancellationModal = async () => {
      const controller = await modalController.create({
        component: RequestCancellationModal,
        componentProps: {},
        cssClass: "",
      });

      await controller.present();
      // catch event?
      controller.onDidDismiss().then(() => {
        GetCancellationRequests();
      });
    };
    return {
      lockClosed,
      checkmark,
      mail,
      phonePortraitOutline,
      logoDiscord,
      reload,
      RefreshUserProfile,
      RefreshSubscriptionProfile,
      GetCancellationRequests,
      user,
      isPlus,
      canTrial,
      Save,
      TestDiscordWebhook,
      OpenCancellationModal,
      hasCancellationRequests,
    };
  },
  mounted() {
    this.RefreshUserProfile();
    this.RefreshSubscriptionProfile();
    this.GetCancellationRequests();
  },
});
</script>
