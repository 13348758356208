<!--
Generic Multiselect Modal Component

Using this instead of the default multiselect
component to provide a better UX for mobile users.

Customizable via props:
title: modal title text

cancelButton: Text for cancel button. By default, a cancel button will not appear
unless you provide a value.

confirmButton: Text for confirm button. Default value is "Confirm". Can be overried by
passing another value to this prop.

items: A collection of objects to appear in the multiselect view

itemIdKey: This property will be called on each item in items and used as the
uuid for the item. :itemDisplayKey="id" -> item.id

itemDisplayKey: This property will be called on each item in items and used as the
display value on screen. :itemDisplayKey="name" -> item.name

itemValueKey: This property will be called on each item in items and used as the
actual value select behind the scenes. :itemValueKey="itemCode" -> item.itemCode
-->

<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons v-if="cancelButton" slot="start">
        <ion-button color="medium">{{ cancelButton }}</ion-button>
      </ion-buttons>
      <ion-title>{{ title }}</ion-title>
      <ion-buttons slot="end">
        <ion-button @click="modalController.dismiss()">{{
          confirmButton
        }}</ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list>
      <ion-item v-for="item in data" :key="item[itemIdKey]">
        <ion-checkbox
          label-placement="end"
          justify="start"
          v-model="item[itemStateKey]"
          @click="updateCheckboxOnClick(item)"
        >
          {{ item[itemDisplayKey] }}
        </ion-checkbox>
      </ion-item>
    </ion-list>
  </ion-content>
</template>
<script lang="ts">
import {
  IonItem,
  IonHeader,
  IonToolbar,
  IonCheckbox,
  IonList,
  IonContent,
  IonButton,
  IonButtons,
  IonTitle,
  modalController,
} from "@ionic/vue";
import { defineComponent, reactive, ref } from "vue";
import { searchOutline } from "ionicons/icons";

export default defineComponent({
  name: "GenericMulitSelectModal",
  components: {
    IonItem,
    IonHeader,
    IonToolbar,
    IonCheckbox,
    IonList,
    IonContent,
    IonButton,
    IonButtons,
    IonTitle,
  },
  emits: ["ItemClicked"],
  setup(props, { emit }) {
    const data = reactive(props.items);

    const updateCheckboxOnClick = (data: any) => {
      //TODO Emit list back to parent when state changes
    };

    return { searchOutline, data, updateCheckboxOnClick, modalController };
  },
  props: {
    title: {
      default: "Select all that apply",
      required: false,
      type: String,
    },
    cancelButton: {
      default: "",
      required: false,
      type: String,
    },
    confirmButton: {
      default: "select",
      required: false,
      type: String,
    },
    items: {
      default: [
        { id: 1, name: "Young Thug", isChecked: false },
        { id: 2, name: "A$AP Rocky", isChecked: false },
        { id: 3, name: "Future", isChecked: false },
      ],
      required: false,
    },
    itemDisplayKey: {
      default: "name",
      required: false,
      type: String,
    },
    itemIdKey: {
      default: "id",
      required: false,
    },
    itemStateKey: {
      default: "isChecked",
      required: false,
      type: String,
    },
  },
});
</script>
