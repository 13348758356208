<template>
  <swiper
    class="hero"
    :modules="modules"
    :pagination="{ clickable: true }"
    :autoplay="true"
  >
    <swiper-slide>Magic: The Gathering</swiper-slide>
    <swiper-slide>Yu-Gi-Oh!</swiper-slide>
    <swiper-slide>Pokémon</swiper-slide>
    <swiper-slide>and so much more.</swiper-slide>
  </swiper>
</template>
<script lang="ts">
import { IonItem } from "@ionic/vue";
import { defineComponent, ref } from "vue";
import { toast } from "../utils/SimpleToast.js";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "@ionic/vue/css/ionic-swiper.css";
import "swiper/css/pagination";

export default defineComponent({
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Pagination, Autoplay],
    };
  },
  mounted() {
    //toast("Mounted starter component");
  },
  //props: {},
});
</script>
<style scoped>
.hero {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  color: #ffffff;
  font-weight: 600;
  line-height: 1.5;
  font-size: 55px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
